/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { useContext, useCallback } from "react"
import { Highlight } from "react-instantsearch-dom"
import Link from "../../components/Link"
import { LocalizationContext } from "../../context/LocalizationContext"
import { sendSearchTermClick } from '../../utils/analytics';
import { generateAncorHash } from "../../utils/utils.js";

export const PageHit = (clickHandler, edges) => ({ hit }) => {
  const { getLocale, language } = useContext(LocalizationContext)
  let loc = getLocale()
  let defaultLocale = "fi-FI"
  let titleAttribute = "title." + loc
  if (!hit.title[loc]) {
    titleAttribute = "title." + defaultLocale
  }
  let body =
    (hit.body && hit.body[loc]) ||
    (hit.contentArea && hit.contentArea[loc]) ||
    (hit.metaDescription && hit.metaDescription[loc]) ||
    (hit.body && hit.body[defaultLocale]) ||
    (hit.contentArea && hit.contentArea[defaultLocale]) ||
    (hit.metaDescription && hit.metaDescription[defaultLocale])
  let link = hit.slug && "/" + hit.slug[loc]
  if (hit.type === "office") {    
    // useSitePages didn't work here 
    link = '/konttorit';
    if (loc === 'sv-SE') link = '/kontor';
    const anchorLink = generateAncorHash(hit.title[loc]);
    if (anchorLink) { 
      link = link + '#'+anchorLink;
    }
  }  
  if (body && body.length > 150) {
    body = body.substring(0, 150) + "..."
  }
  const clickF = (e) => {
    sendSearchTermClick(link);
    clickHandler(e);
  };
  // find parent url
  if (edges) {
    let edge = edges.find(
      edge =>
        edge.context &&
        edge.context.contentful_id === hit.contentId &&
        edge.context.node_locale === loc
    )
    if (
      edge &&
      edge.context &&
      edge.context.paths &&
      edge.context.paths[language]
    ) {
      link = edge.context.paths[language]
    }
  }
  return (
    <Box
      as={Link}
      target={link}
      onClick={clickF}
      sx={{
        display: "block",
        my: 3,
        lineHeight: "normal",
        textDecoration: "none"
      }}
    >
      <Box
        sx={{
          fontFamily: "heading",
          mb: 1,
          color: "primary",
          mark: { bg: "lightGreen" },
        }}
      >
        <Highlight attribute={titleAttribute} hit={hit} tagName="mark" />
      </Box>
      <Box sx={{ fontSize: 1, color: "text" }}>{body}</Box>
    </Box>
  )
}
