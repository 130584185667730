import { useStaticQuery, graphql } from "gatsby";

export const useSitePages = () => {
  const { allSitePage } = useStaticQuery(
    graphql`
      query AllContentfulNodesQuery {
        allSitePage {
          nodes {
            path
            context {
              id
              contentful_id
              __typename
              slug
              language
              node_locale
              paths {
                fi
                sv
                en
              }
              titles {
                fi
                sv
                en
              }
            }
          }
        }
      }
    `
  );
  return allSitePage.nodes;
};
