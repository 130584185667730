/** @jsx jsx */
import { jsx, Flex, Box } from "theme-ui";
import SearchAlgoliaComponent from "../components/searchwidget";
import { useSitePages } from "../hooks/useSitePages";
import { SearchIcon, CloseIcon } from "./Icon";

const searchIndices = [
  {
    name: `${process.env.ALGOLIA_SEARCH_INDEX}`,
    title: `Pages`,
    hitComp: `PageHit`,
  },
];

const Search = ({ show, toggleSearch, ...rest }) => {
  const nodes = useSitePages();

  return (
    <Box
      sx={{
        position: ["relative", null, "absolute"],
        top: 0,
        left: 0,
        display: ["block", null, show ? "flex" : "none"],
        alignItems: [null, null, "flex-end"],
        width: "100%",
        height: ["auto", null, "100%"],
        bg: "white",
        zIndex: 999,
      }}
    >
      <Flex
        sx={{
          position: "relative",
          zIndex: 200,
          bg: "muted",
          flex: 1,
          justifyContent: "space-between",
          minWidth: 160,
          alignItems: "center",
          ml: [0, null, 5, 7],
          mr: [0, null, 3, 4],
        }}
        {...rest}
      >
        <SearchAlgoliaComponent
          collapse
          indices={searchIndices}
          edges={nodes}
          show={show}
          toggleSearch={toggleSearch}
        />
        <SearchIcon
          sx={{ position: "absolute", right: 2, top: ["12px", null, 1] }}
        />
      </Flex>
      <Box
        sx={{
          display: ["none", null, "flex"],
          flex: "none",
          justifyContent: "flex-end",
          cursor: "pointer",
        }}
        onClick={toggleSearch}
      >
        <CloseIcon
          sx={{ mb: "10px", mr: "12px" }}
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === "Esc" || event.key === "Enter") {
              toggleSearch();
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default Search;
