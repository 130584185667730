import { useStaticQuery, graphql } from "gatsby";

export const useContentfulFeedback = () => {
  const { allContentfulComponent } = useStaticQuery(
    graphql`
      query ContentfulFeedbackQuery {
        allContentfulComponent(filter: { template: { eq: "FeedbackSurvey" } }) {
          edges {
            node {
              id
              contentful_id
              __typename
              node_locale
              data {
                siteSurveyKT {
                  displayFrequencyPercent
                  timeDelaySeconds
                  surveyTitle
                  surveyIntro
                  surveyUrl
                }
                siteSurveyYR {
                  displayFrequencyPercent
                  timeDelaySeconds
                  surveyTitle
                  surveyIntro
                  surveyUrl
                }
                omaFenniaSurveyKT {
                  surveyTitle
                  surveyIntro
                  surveyUrl
                }
                omaFenniaSurveyYR {
                  surveyTitle
                  surveyIntro
                  surveyUrl
                }
                hfofSurvey {
                  surveyTitle
                  surveyIntro
                  surveyUrl
                }
              }
            }
          }
        }
      }
    `
  );

  const feedbackConfigs = Array.isArray(allContentfulComponent.edges)
    ? allContentfulComponent.edges.map((item) => {
        return {
          ...item.node.data,
          locale: item.node.node_locale,
        };
      })
    : [];

  return feedbackConfigs;
};
