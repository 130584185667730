import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useSiteMetadata } from "../hooks/use-site-metadata";

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  altTitle,
  image,
  paths,
  hidden,
  noIndex,
  noSnippet,
  metadataTitle,
  canonicalTag,
}) {
  const {
    defaultTitle,
    titleTemplate,
    titleTemplateSv,
    defaultDescription,
    siteUrl,
    defaultImage,
    social,
    author,
  } = useSiteMetadata();

  const metaDescription = description || defaultDescription;
  const metaTitle = metadataTitle || title || defaultTitle;
  const metaImage = image || siteUrl + defaultImage;

  const metaDataHandler = () => {
    const metaList = [
      {
        name: `image`,
        content: metaImage,
      },
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: metaTitle,
      },
      {
        property: `og:description`,
        content: metaDescription,
      },
      {
        property: `og:image`,
        content: metaImage,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:image`,
        content: metaImage,
      },
      {
        name: `twitter:creator`,
        content: social.twitter,
      },
      {
        name: `twitter:title`,
        content: metaTitle,
      },
      {
        name: `twitter:description`,
        content: metaDescription,
      },
    ];
    if (keywords.length > 0) {
      metaList.push({
        name: `keywords`,
        content: keywords.join(`, `),
      });
    }
    if (hidden || noIndex || noSnippet) {
      metaList.push({
        name: `robots`,
        content: metaRobotsHandler(hidden, noIndex, noSnippet),
      });
    }
    
    return metaList;
  };

  const metaRobotsHandler = (hidden, noIndex, noSnippet) => {
    if (hidden || noIndex) {
      return "noindex";
    }
    if (noSnippet) {
      return "nosnippet";
    }
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={altTitle ? altTitle : titleTemplate}
      meta={metaDataHandler()}
    >
      {paths && paths["fi"] && (
        <link
          rel="alternate"
          href={process.env.GATSBY_WEB_URL + paths["fi"]}
          hreflang="fi-fi"
        />
      )}
      {paths && paths["sv"] && (
        <link
          rel="alternate"
          href={process.env.GATSBY_WEB_URL + paths["sv"]}
          hreflang="sv-fi"
        />
      )}
      {paths && paths["fi"] && (
        <link
          rel="alternate"
          href={process.env.GATSBY_WEB_URL + paths["fi"]}
          hreflang="x-default"
        />
      )}
      {canonicalTag && (
        <link rel="canonical" key={canonicalTag} href={canonicalTag} />
      )}

      <link rel="apple-touch-icon" href="/icon.png" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `fi`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default SEO;
