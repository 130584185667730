/** @jsx jsx */
import { jsx, Box } from "theme-ui";

const DropdownMenu = ({ showMenu, children, ...rest }) => (
  <Box
    as="ul"
    sx={{
      visibility: showMenu ? "visible" : "hidden",
      position: ["static", null, "absolute"],
      height: [showMenu ? "auto" : 0, null, "auto"],
      left: -2,
      zIndex: 1,
      listStyle: "none",
      bg: "white",
      px: 2,
      py: [0, null, 1],
      pb: [showMenu ? 3 : 0, null, 1],
      boxShadow: ["none", null, "dropdown"],
    }}
    {...rest}
  >
    {children}
  </Box>
);

export default DropdownMenu;
