/** @jsx jsx */
import dayjs from "dayjs";
import { useContext, useState, useEffect } from "react";
import { Box, Button, Flex, jsx } from "theme-ui";
import { FaultNotificationContext } from "../context/FaultNotificationContext";
import { LocalizationContext } from "../context/LocalizationContext";
import colors from "../gatsby-plugin-theme-ui/colors";
import { useWindowSize } from "../hooks/useWindowSize";
import {
  getClosedNotificationsToSessionStorage,
  saveFaultNotificationsToSessionStorage,
  saveClosedNotificationsToSessionStorage,
} from "../utils/utils";
import { CloseIcon, WarningIcon } from "./Icon";
import Link from "./Link";

const TABLET_BREAKPOINT = 1136;
const MOBILE_BREAKPOINT = 768;

const isTablet = (currentWidth) => {
  return currentWidth < TABLET_BREAKPOINT;
};

const isMobile = (currentWidth) => {
  return currentWidth < MOBILE_BREAKPOINT;
};

const ErrorTypes = {
  Info: "informational",
  Warning: "faultNotificationBackground",
  Error: "error",
};

const FaultNotifications = ({
  faultNotifications,
  locale,
  fetchDateTime,
  menu,
  showMobileLoginMenu,
}) => {
  const { width: viewPortWidth } = useWindowSize();
  const { setFaultNotifications } = useContext(FaultNotificationContext);
  const { translate } = useContext(LocalizationContext);

  const handleCloseClick = (id) => {
    const removedFaultNotifications =
      getClosedNotificationsToSessionStorage() || [];
    const removedNotification = faultNotifications.find(
      (notification) => notification.id === id
    ).id;
    const addToRemovedNotifications = [
      ...removedFaultNotifications,
      removedNotification,
    ];
    const filterIdAway = faultNotifications.filter(
      (notification) => notification.id !== id
    );
    setFaultNotifications(filterIdAway);
    saveClosedNotificationsToSessionStorage(addToRemovedNotifications);
    saveFaultNotificationsToSessionStorage(filterIdAway, fetchDateTime);
  };

  const handleReadMoreClick = (notificationId) => {
    const updatedFaultNotifications = faultNotifications.map((notification) => {
      if (notification.id === notificationId) {
        return {
          ...notification,
          expanded: true,
        };
      }
      return notification;
    });

    setFaultNotifications(updatedFaultNotifications);
    saveFaultNotificationsToSessionStorage(
      updatedFaultNotifications,
      fetchDateTime
    );
  };

  if (
    !Array.isArray(faultNotifications) ||
    !fetchDateTime ||
    (isTablet(viewPortWidth) && menu) ||
    showMobileLoginMenu
  )
    return null;

  return (
    <Flex sx={{ mb: 2, gap: 1, flexDirection: "column" }}>
      {faultNotifications.map((notification, index) => {
        const { message, validFrom, validTo, notificationPages } = notification;

        const validFromDateTime = dayjs(validFrom);
        const validToDateTime = dayjs(validTo);
        // Notifications for certain pages

        const updatedPageEndsWithSlash =
          window.location.pathname.slice(-1) === "/";
        let urlPath = updatedPageEndsWithSlash
          ? window &&
            window.location &&
            window.location.pathname &&
            window.location.pathname.slice(0, -1)
          : window && window.location && window.location.pathname;

        // add support for frontpage
        urlPath =
          window.location.pathname === "/" ? window.location.pathname : urlPath;

        if (
          (notificationPages && !notificationPages.includes(urlPath)) ||
          !message[locale]
        ) {
          return null;
        }
        if (
          validFromDateTime.isBefore(fetchDateTime) &&
          validToDateTime.isAfter(fetchDateTime)
        ) {
          return (
            <NotificationContainer
              locale={locale}
              index={index}
              viewPortWidth={viewPortWidth}
              translate={translate}
              handleCloseClick={handleCloseClick}
              faultNotifications={faultNotifications}
              handleReadMoreClick={handleReadMoreClick}
              notification={notification}
            />
          );
        }
        return null;
      })}
    </Flex>
  );
};

const NotificationContainer = ({
  locale,
  index,
  viewPortWidth,
  translate,
  handleCloseClick,
  faultNotifications,
  handleReadMoreClick,
  notification,
}) => {
  const { severity, id } = notification;

  const notificationBackgroundColor = ErrorTypes[severity];
  const notificationTextColor =
    ErrorTypes[severity] === ErrorTypes.Warning ? "text" : "white";

  return (
    <Flex
      key={index}
      sx={{
        flexDirection: "row",
        backgroundColor: notificationBackgroundColor,
        color: notificationTextColor,
        p: 2,
        width: "100%",
      }}
    >
      <WarningIcon
        sx={{
          fill: notificationTextColor,
          alignSelf: "center",
          minWidth: "16px",
        }}
      />
      <Box
        sx={{
          ml: 3,
          mr: 3,
        }}
      >
        <NotificationContent
          locale={locale}
          viewPortWidth={viewPortWidth}
          translate={translate}
          faultNotifications={faultNotifications}
          handleReadMoreClick={handleReadMoreClick}
          notification={notification}
        />
      </Box>
      <Box sx={{ display: "flex", ml: 3 }}>
        <Button
          aria-label={translate("close")}
          sx={{
            alignSelf: "center",
            position: "absolute",
            right: 0,
            minWidth: "16px",
            "&:hover": {
              cursor: "pointer",
              background: "transparent",
            },
            background: "transparent",
          }}
          onClick={() => handleCloseClick(id)}
        >
          <CloseIcon />
        </Button>
      </Box>
    </Flex>
  );
};

const NotificationContent = ({
  locale,
  viewPortWidth,
  translate,
  faultNotifications,
  handleReadMoreClick,
  notification,
}) => {
  const { message, severity, link, linkText, id, expanded } = notification;

  const [showLink, setShowLink] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const maxLength = 50;
  const messageLength = message?.[locale]?.length || 0;
  const linkTextLength = linkText?.[locale]?.length || 0;
  const combinedLength = messageLength + linkTextLength;
  const overLimit = combinedLength > maxLength;
  const [messageSlice, setMessageSlice] = useState(messageLength);
  const mobileViewPort = isMobile(viewPortWidth);

  useEffect(() => {
    if (mobileViewPort && !expanded) {
      setShowButton(true);
      setShowLink(!overLimit);
      setMessageSlice(overLimit ? maxLength : messageLength);
    } else {
      setShowButton(false);
      setShowLink(true);
      setMessageSlice(messageLength);
    }
  }, [mobileViewPort, faultNotifications]);

  return (
    <Box
      sx={{
        lineHeight: [1.2, 1.2, 1.5],
        fontSize: [1, 1, 2],
      }}
    >
      <Box
        sx={{
          display: "inline",
        }}
      >
        {message[locale].slice(0, messageSlice)}
      </Box>
      {link && showLink && linkTextLength > 0 ? (
        <Box
          sx={{
            display: "inline",
          }}
        >
          <Link
            target={link[locale]}
            sx={{
              color: severity === "Warning" ? colors.darkGrey : "white",
              textDecoration: "underline",
              fontWeight: "bold",
              "&:hover": {
                color: severity === "Warning" ? colors.black : colors.muted,
              },
              ml: 1,
            }}
          >
            {linkText[locale]}
          </Link>
        </Box>
      ) : (
        ""
      )}
      {mobileViewPort && overLimit && !expanded ? "... " : " "}
      {showButton && (
        <Button
          onClick={() => handleReadMoreClick(id)}
          variant="plain"
          sx={{
            fontSize: 1,
            color: severity === "Warning" ? colors.darkGrey : "white",
            fontWeight: "bold",
            "&:hover": {
              color: severity === "Warning" ? colors.black : colors.muted,
            },
          }}
        >
          {overLimit && !expanded ? translate("readMore") : ""}
        </Button>
      )}
    </Box>
  );
};

export default FaultNotifications;
