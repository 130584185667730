/** @jsx jsx */
import { jsx } from "theme-ui";
import Link from "../Link";

const DropdownLink = (props) => (
  <li>
    <Link
      sx={{
        display: "block",
        py: 1,
        whiteSpace: "nowrap",
        color: "primary",
        textDecoration: "none",
        fontSize: [3, null, 1],
        ":hover": { color: "#00A830" },
      }}
      {...props}
    >
      {props.children}
    </Link>
  </li>
);

export default DropdownLink;
