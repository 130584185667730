/** @jsx jsx */
import { jsx, Flex, Box, useThemeUI } from "theme-ui";
import Link from "../Link";
import Menu from "./Menu";
import LoginMenu from "./LoginMenu";
import Search from "../Search";
import LanguageSelector from "../LanguageSelector";
import { SearchIcon } from "../Icon";
import { useState, useContext, useRef } from "react";
import { LocalizationContext } from "../../context/LocalizationContext";
import { useContentfulMenu } from "../../hooks/useContentfulMenu";
import { useContentfulLoginMenu } from "../../hooks/useContentfulLoginMenu";
import { PageTypes } from "../Layout";

const MenuWrapper = (props) => {
  const { translate } = useContext(LocalizationContext);
  const [showSearch, setShowSearch] = useState(false);
  const { theme } = useThemeUI();

  const menuComponents = useContentfulMenu();
  const loginMenuComponents = useContentfulLoginMenu();
  const {
    localizedFrontPageSlug,
    localizedCompanyFrontPageSlug,
    pageType,
    menuTypeSelectionHandler,
    menuIsVisible,
    locale,
    language,
    showMobileLoginMenu,
    toggleOpen,
  } = props;

  const { menu: headerMenu } = menuComponents.reduce((menuObj, component) => {
    if (pageType === component.pageType && locale === component.locale) {
      menuObj.menu = component.menu;
    }
    return menuObj;
  }, {});

  const { menu: loginMenu } =
    pageType !== PageTypes.Concern
      ? loginMenuComponents.reduce((menuObj, component) => {
          if (locale === component.locale) {
            menuObj.menu = component.menu;
          }
          return menuObj;
        }, {})
      : {};

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const refs = useRef([]);
  const [menuIsOpen, setMenuIsOpen] = useState(headerMenu.map(() => false));
  const [loginMenuIsOpen, setLoginMenuIsOpen] = useState(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
  const [loginMenuTopLevelIndex, setLoginMenuTopLevelIndex] = useState(-1);

  const openDropDownMenu = (position) => {
    if (position === headerMenu.length) {
      setLoginMenuIsOpen((prevState) => !prevState);
    } else {
      if (loginMenuIsOpen) {
        setLoginMenuIsOpen(false);
      }
      toggleSelectedMenu(position);
    }
  };

  const onKeyDownHandler = (e, index) => {
    if (e.key === "ArrowLeft") {
      setFocusedItemIndex(-1);
      setLoginMenuTopLevelIndex(-1);
      const position = index - 1;
      if (position > -1) {
        openDropDownMenu(position);
        refs.current[position].focus();
      }
    }

    if (e.key === "ArrowRight") {
      setFocusedItemIndex(-1);
      setLoginMenuTopLevelIndex(-1);
      const position = index + 1;
      if (position < refs.current.length) {
        openDropDownMenu(position);
        refs.current[position].focus();
      }
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();

      // Default menu functionality
      if (index < headerMenu.length) {
        if (!menuIsOpen[index]) {
          toggleSelectedMenu(index);
        } else if (refs.current[index].lastChild.children) {
          if (focusedItemIndex < 0) {
            refs.current[index].lastChild.firstChild.firstChild.focus();
            setFocusedItemIndex(0);
          } else {
            const nextIndex =
              focusedItemIndex !==
              refs.current[index].lastChild.children.length - 1
                ? focusedItemIndex + 1
                : 0;
            refs.current[index].lastChild.children[
              nextIndex
            ].firstChild.focus();
            setFocusedItemIndex(nextIndex);
          }
        }
      }

      // LoginMenu functionality
      if (index === headerMenu.length) {
        if (!loginMenuIsOpen) {
          setLoginMenuIsOpen((prevState) => !prevState);
        } else if (refs.current[index].lastChild.children) {
          if (loginMenuTopLevelIndex < 0) {
            refs.current[index].lastChild.children[
              loginMenuTopLevelIndex >= 0 ? loginMenuTopLevelIndex : 0
            ].children[1].firstChild.firstChild.focus();
            setLoginMenuTopLevelIndex(0);
            setFocusedItemIndex(0);
          } else {
            const links =
              refs.current[index].lastChild.children[loginMenuTopLevelIndex]
                .children[1].children;
            const nextIndex =
              focusedItemIndex !==
              refs.current[index].lastChild.children[loginMenuTopLevelIndex]
                .children[1].children.length
                ? focusedItemIndex + 1
                : 0;
            if (links.length === nextIndex) {
              const nextTopLevelIndex = loginMenuTopLevelIndex ? 0 : 1;
              refs.current[index].lastChild.children[
                nextTopLevelIndex
              ].children[1].firstChild.firstChild.focus();
              setLoginMenuTopLevelIndex(nextTopLevelIndex);
              setFocusedItemIndex(0);
            } else {
              links[nextIndex]?.firstChild.focus();
              setFocusedItemIndex(nextIndex);
            }
          }
        }
      }
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      if (
        menuIsOpen[index] &&
        focusedItemIndex > -1 &&
        refs.current[index].lastChild.children
      ) {
        const nextIndex =
          focusedItemIndex !== 0
            ? focusedItemIndex - 1
            : refs.current[index].lastChild.children.length - 1;
        refs.current[index].lastChild.children[nextIndex].firstChild.focus();
        setFocusedItemIndex(nextIndex);
      }

      // LoginMenu functionality
      if (
        index === headerMenu.length &&
        loginMenuIsOpen &&
        focusedItemIndex > -1
      ) {
        if (loginMenuTopLevelIndex < 0) {
          const nextLoginMenuTopLevelIndex =
            refs.current[index].lastChild.children.length - 1;
          refs.current[index].lastChild.children[
            nextLoginMenuTopLevelIndex
          ].children[1].lastChild.firstChild.focus();
          setLoginMenuTopLevelIndex(nextLoginMenuTopLevelIndex);
          setFocusedItemIndex(
            refs.current[index].lastChild.children[
              refs.current[index].lastChild.children.length - 1
            ].children.length - 1
          );
        } else {
          const links =
            refs.current[index].lastChild.children[loginMenuTopLevelIndex]
              .children[1].children;
          const nextIndex = focusedItemIndex - 1;
          if (nextIndex < 0) {
            const nextTopLevelIndex = loginMenuTopLevelIndex - 1 >= 0 ? 0 : 1;
            refs.current[index].lastChild.children[
              nextTopLevelIndex
            ].children[1].lastChild.firstChild.focus();
            setLoginMenuTopLevelIndex(nextTopLevelIndex);
            setFocusedItemIndex(
              refs.current[index].lastChild.children[nextTopLevelIndex]
                .children[1].children.length - 1
            );
          } else {
            links[nextIndex]?.firstChild.focus();
            setFocusedItemIndex(nextIndex);
          }
        }
      }
    }

    if (["Enter", " "].includes(e.key)) {
      e.preventDefault();
      if (index <= headerMenu.length) {
        openDropDownMenu(index);
      } else {
        toggleSearch();
      }
    }
  };

  const toggleSelectedMenu = (index) => {
    setMenuIsOpen((prevState) => {
      const newState = prevState.map((item, i) =>
        i === index || item ? !item : item
      ); // Toggle current, close others if open
      return newState;
    });
  };

  return (
    <Flex
      onFocus={(e) => {
        toggleOpen(true);
      }}
      sx={{
        position: ["fixed", null, "relative"],
        top: [64, null, 0],
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
        flex: 1,
        px: [2, 3, 0],
        flexDirection: "column",
        alignItems: [null, null, "flex-end"],
        visibility: [menuIsVisible ? "visible" : "hidden", null, "visible"],
        bg: ["white", null, "transparent"],
        overflowY: ["auto", null, "visible"],
        "@media print": {
          display: "none",
        },
      }}
    >
      <Flex
        sx={{
          flex: "none",
          alignItems: ["flex-start", null, "center"],
          flexDirection: ["column", null, "row"],
          order: [0, null, 1],
          width: [null, null, "100%"],
          justifyContent: [null, null, "flex-end"],
        }}
      >
        <Menu
          pageType={pageType}
          headerMenu={headerMenu}
          menuIsVisible={menuIsVisible}
          showMenu={menuIsOpen}
          onKeyDownHandler={onKeyDownHandler}
          toggleSelectedMenu={toggleSelectedMenu}
          forwardRef={refs}
          sx={{
            order: [1, null, 0],
            mt: [2, null, 0],
            display: showMobileLoginMenu ? "none" : "",
          }}
        />
        {loginMenu && (
          <LoginMenu
            loginMenu={loginMenu}
            showLoginMenu={loginMenuIsOpen}
            toggleOpen={setLoginMenuIsOpen}
            showMobileLoginMenu={showMobileLoginMenu}
            onKeyDown={(event) => onKeyDownHandler(event, headerMenu.length)}
            forwardRef={(element) =>
              (refs.current[headerMenu.length] = element)
            }
            tabIndex={-1}
          />
        )}
        <Flex
          p={1}
          ml={2}
          bg="muted"
          sx={{
            display: ["none", null, "flex"],
            alignItems: "center",
            flex: "1 1 auto",
            maxWidth: "23%",
          }}
          onClick={toggleSearch}
        >
          <Box
            sx={{
              flex: "1 1 auto",
              cursor: "text",
              lineHeight: 1.0,
              fontSize: [2, null, 1],
            }}
          >
            {translate("search.title")}
          </Box>
          <Box>
            <SearchIcon
              sx={{
                cursor: "pointer",
                ml: 2,
              }}
              onKeyDown={(event) =>
                onKeyDownHandler(event, headerMenu.length + 1)
              }
              aria-label={translate("menu.search", null, language)}
              role="button"
              forwardRef={(element) =>
                (refs.current[headerMenu.length + 1] = element)
              }
              tabIndex={0}
            />
          </Box>
        </Flex>
        <Search
          sx={{ display: showMobileLoginMenu ? "none" : ["flex"], order: 0 }}
          show={showSearch}
          toggleSearch={toggleSearch}
        />
      </Flex>
      <Flex
        sx={{
          flex: "none",
          flexDirection: ["column", null, "row"],
          alignItems: ["flex-start", null, "center"],
          order: [1, null, 0],
          mt: [0, null, 0],
          mb: [3, null, 2],
        }}
      >
        <Link
          variant="header"
          target={localizedFrontPageSlug}
          onClick={menuTypeSelectionHandler}
          sx={{
            flex: [1, null, "none"],
            fontFamily: ["heading", null, "body"],
            fontSize: [2, null, 1],
            fontWeight:
              pageType === PageTypes.PrivateCustomer ? "bold" : "normal",
            borderBottom:
              pageType === PageTypes.PrivateCustomer
                ? [0, null, `2px solid ${theme.colors.primary}`]
                : "0",
            py: [2, null, 0],
            mx: [0, null, 1],
            width: ["100%", null, "auto"],
            visibility: showMobileLoginMenu || showSearch ? "hidden" : "",
          }}
        >
          {translate("menu.privateCustomer", null, language)}
        </Link>
        <Link
          variant="header"
          target={localizedCompanyFrontPageSlug}
          onClick={menuTypeSelectionHandler}
          sx={{
            flex: [1, null, "none"],
            fontFamily: ["heading", null, "body"],
            fontSize: [2, null, 1],
            fontWeight:
              pageType === PageTypes.CompanyCustomer ? "bold" : "normal",
            borderBottom:
              pageType === PageTypes.CompanyCustomer
                ? [0, null, `2px solid ${theme.colors.primary}`]
                : "0",
            mx: [0, null, 1],
            py: [2, null, 0],
            width: ["100%", null, "auto"],
            visibility: showMobileLoginMenu || showSearch ? "hidden" : "",
          }}
        >
          {translate("menu.companyCustomer", null, language)}
        </Link>
        <Box
          sx={{
            display: showMobileLoginMenu ? "none" : "",
            mx: [0, null, 2],
            mb: [3, null, 0],
            width: ["100%", null, 1],
            height: [1, null, 16],
            bg: "lightGrey",
          }}
        />
        <LanguageSelector
          language={language}
          sx={{
            visibility: showMobileLoginMenu || showSearch ? "hidden" : "",
          }}
        />
      </Flex>
    </Flex>
  );
};

export default MenuWrapper;
