/** @jsx jsx */
import { jsx, Themed, Box } from "theme-ui";
import { /*useEffect, useContext,*/ useState } from "react";
import { isMobile } from "react-device-detect";
import Dropdown from "./Dropdown";
import DropdownLink from "./DropdownLink";
import DropdownMenu from "./DropdownMenu";

// import { getCookies } from "../utils/utils"; ### Intended for later use
// import { UserContext } from "../context/UserContext"; ### Intendend for later use

const LoginMenu = ({
  loginMenu,
  showLoginMenu,
  toggleOpen,
  showMobileLoginMenu,
  forwardRef,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);
  // const { username, setUsername } = useContext(UserContext); ### Intendend for later use
  let timeOutId = null;

  const onMouseEnterHandler = () => {
    if (isMobile) return;
    if (!showLoginMenu) {
      setIsHovered(true);
    }
  };

  const onMouseLeaveHandler = () => {
    if (isMobile) return;
    setIsHovered(false);
  };

  const onFocusHandler = () => {
    toggleOpen(true);
    clearTimeout(timeOutId);
  };

  const onBlurHandler = () => {
    /*timeOutId = setTimeout(() => {
      toggleOpen(false);
    });*/
  };

  const onClickHandler = () => {
    if (!showLoginMenu) {
      setIsHovered(false);
    }
    toggleOpen((prevState) => !prevState);
  };

  return (
    <Dropdown
      title={loginMenu.title}
      key={loginMenu.title}
      showAccordion={showMobileLoginMenu ? false : true}
      sx={{
        display: showMobileLoginMenu ? "block" : ["none", null, "block"],
        borderBottomWidth: 0,
      }}
      buttonStyle={{
        color: ["text", null, "primary"],
        fontWeight: "bold",
        fontSize: [5, null, 1],
        whiteSpace: "normal",
        py: [0, null, 0],
      }}
      onClick={onClickHandler}
      onBlur={onBlurHandler}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      isHovered={isHovered}
      isOpen={showLoginMenu}
      forwardRef={forwardRef}
      {...rest}
    >
      <DropdownMenu
        showMenu={showLoginMenu || isHovered || showMobileLoginMenu}
        sx={{
          px: [0, null, 2],
        }}
      >
        {loginMenu.items.map(({ title, subItems }) => {
          return (
            <Box key={title}>
              <Themed.h4
                as="div"
                sx={{
                  fontSize: [3, null, 1],
                  mt: 2,
                  mb: 1,
                }}
              >
                {title}
              </Themed.h4>
              <Box>
                {subItems &&
                  subItems.map(({ title, slug }) => {
                    return (
                      <DropdownLink
                        target={slug}
                        key={title}
                        onFocus={onFocusHandler}
                        sx={{
                          px: [2, null, 0],
                        }}
                      >
                        {title}
                      </DropdownLink>
                    );
                  })}
              </Box>
              <Box
                sx={{
                  display: ["block", null, "none"],
                  mx: [0, null, 2],
                  mt: [2, null, 0],
                  mb: [3, null, 0],
                  width: ["100%", null, 1],
                  height: [1, null, 16],
                  bg: "lightGrey",
                }}
              />
            </Box>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LoginMenu;
