/** @jsx jsx */
import { jsx, Box, Flex, Container } from "theme-ui";
import { useContext } from "react";
import Logo from "./Logo";
import Link from "./Link";
import { useContenfulFooterMenu } from "../hooks/useContentfulFooterMenu";
import { LocalizationContext } from "../context/LocalizationContext";
import { useSitePages } from "../hooks/useSitePages";
import { getLocalizedUrlAndTitleFromSlug } from "../utils/utils";
import { PageTypes } from "./Layout";
import { SomeLinks } from "./SomeLinks";

const Footer = ({
  pageType: originalPageType,
  menuTypeSelectionHandler,
  locale,
  language,
}) => {
  const { translate } = useContext(LocalizationContext);
  const menuComponents = useContenfulFooterMenu();
  const pages = useSitePages();
  const pageType =
    originalPageType === PageTypes.Concern
      ? PageTypes.PrivateCustomer
      : originalPageType; // Footer menu exists only for private and company page types

  const { slug: localizedFrontPageSlug } = getLocalizedUrlAndTitleFromSlug(
    "/",
    language,
    pages
  );
  const { slug: localizedCompanyFrontPageSlug } =
    getLocalizedUrlAndTitleFromSlug("/etusivu-yritys", language, pages);

  const { slug: localizedContactInformationSlug } =
    getLocalizedUrlAndTitleFromSlug(
      "/fennia-konsernin-yhtioiden-paakonttorit",
      language,
      pages
    );

  const {
    menu: footerMenu,
    contactInformation,
    subItems,
  } = menuComponents.reduce((menuObj, component) => {
    if (pageType === component.pageType && component.locale === locale) {
      menuObj.menu = component.menu;
      menuObj.contactInformation = component.contactInformation;
      menuObj.subItems = component.subItems;
    }
    return menuObj;
  }, {});

  const setMenuTypeHandler = (slug) => {
    // Set menuTypeSelectionHandler for private customer / company customer selection
    if (
      ["/", localizedFrontPageSlug, localizedCompanyFrontPageSlug].includes(
        slug
      )
    ) {
      return menuTypeSelectionHandler;
    }
  };

  const handleGridArea = (menuLength) => {
    switch (menuLength) {
      case 9:
        return [
          "unset",
          `
            'area1 area5'
            'area2 area6'
            'area3 area7'
            'area4 area8'
            '. area9'
          `,
          null,
          `
            'area1 area3 area5 area7'
            'area2 area4 area6 area8'
            '. . . area9'
          `,
        ];
      case 10:
        return [
          "unset",
          `
            'area1 area6'
            'area2 area7'
            'area3 area8'
            'area4 area9'
            'area5 area10'
            '. area11'
          `,
          null,
          `
            'area1 area3 area5 area8'
            'area2 area4 area6 area9'
            '. . area7 area10'
          `,
        ];
      case 11:
        return [
          "unset",
          `
            'area1 area6'
            'area2 area7'
            'area3 area8'
            'area4 area9'
            'area5 area10'
            '. area11'
          `,
          null,
          `
            'area1 area3 area6 area9'
            'area2 area4 area7 area10'
            '. area5 area8 area11'
          `,
        ];
      case 12:
        return [
          "unset",
          `
            'area1 area7'
            'area2 area8'
            'area3 area9'
            'area4 area10'
            'area5 area11'
            'area6 area12'
          `,
          null,
          `
            'area1 area4 area7 area10'
            'area2 area5 area8 area11'
            'area3 area6 area9 area12'
          `,
        ];
    }
  };

  return (
    <footer sx={{ variant: "layout.footer" }}>
      <Container sx={{ py: 5 }}>
        <Flex
          sx={{
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ width: ["auto", "25%", null, "18%"], mb: [3, 0] }}>
            <Link
              target={
                pageType === PageTypes.CompanyCustomer
                  ? localizedCompanyFrontPageSlug
                  : localizedFrontPageSlug
              }
              aria-label={translate("logo.alt")}
            >
              <Logo sx={{ color: "white", width: 72, height: 20 }} />
            </Link>
          </Box>
          <Box
            sx={{
              width: ["100%", "75%", null, "82%"],
              mt: [2, 0],
              display: "grid",
              columnGap: 2,
              rowGap: [1, 0],
              gridTemplateColumns: ["unset", "auto"],
              gridTemplateAreas: handleGridArea(footerMenu.length),
            }}
          >
            {footerMenu &&
              footerMenu.map(({ title, slug }, index) => {
                return (
                  <Link
                    variant="footer"
                    target={slug}
                    key={title}
                    onClick={setMenuTypeHandler(slug)}
                    sx={{
                      fontSize: 2,
                      gridArea: ["unset", `area${index + 1}`],
                    }}
                  >
                    {title}
                  </Link>
                );
              })}
          </Box>
        </Flex>
        <Flex
          sx={{
            flexDirection: ["column", null, null, "row"],
            mt: [4, null, 5],
            fontSize: 0,
            columnGap: 4,
            rowGap: 1,
          }}
        >
          <Box
            sx={{
              mt: [4, null, null, 0],
            }}
          >
            © {translate("footer.concernName")}
          </Box>

          <Link
            sx={{
              mt: [2, null, null, 0],
            }}
            target={localizedContactInformationSlug}
            variant="footer"
          >
            {translate("footer.contactInformation")}
          </Link>

          {subItems.map(({ title, slug }) => (
            <Link
              key={title}
              variant="footer"
              target={slug}
              sx={{
                mb: [0, 0],
              }}
            >
              {title}
            </Link>
          ))}
          <SomeLinks />
        </Flex>
      </Container>
    </footer>
  );
};

export default Footer;
