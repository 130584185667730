/** @jsx jsx */
import { useState, useEffect, useRef, useContext, useCallback } from "react";
import { jsx, Flex, Box, Input as DummmyInput } from "theme-ui";
import {
  InstantSearch,
  Index,
  Hits,
  connectStateResults,
} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
//import { Input as DummmyInput } from "./styles";
import { Root } from "./styles";
import Input from "./input";
import * as hitComps from "./hitComps";
import { SearchStore } from "../../context/SearchContext";
import { LocalizationContext } from "../../context/LocalizationContext";
import VisuallyHidden from "../VisuallyHidden";
import { sendSearchTerm } from "../../utils/analytics";
import { SearchContext } from "../../context/SearchContext";

const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) => {
    const { translate } = useContext(LocalizationContext);
    return res && res.nbHits > 0 ? (
      children
    ) : (
      <Box sx={{ textAlign: "center", my: 3 }}>
        {translate("search.noResults", { query: state.query })}
      </Box>
    );
  }
);

const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`
);

const searchClient = algoliasearch(
  process.env.ALGOLIA_APP_ID,
  process.env.ALGOLIA_SEARCH_KEY,
  {
    // algolia duplicate search bug, see https://github.com/algolia/react-instantsearch/issues/355
    _useRequestCache: true,
  }
);

const useClickOutside = (ref, handler, events) => {
  if (!events) events = [`mousedown`, `touchstart`];
  const detectClickOutside = event =>
    !ref && !ref.current && !ref.current.contains(event.target) && handler();
  useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside);
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside);
    };
  });
};

const SearchAlgoliaComponent = ({
  indices,
  collapse,
  hitsAsGrid,
  edges,
  show,
  toggleSearch,
}) => {
  const ref = useRef();
  const [query, setQuery] = useState("");
  const [focus, setFocus] = useState(false);
  const [searchStatus, setSearchStatus] = useState(false);
  const { translate } = useContext(LocalizationContext);
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const triggerChange = e => {
    const val = e.target.value;
    // do not search when there is not enough characters
    setQuery(val);
    setSearchStatus(val.length >= 3);
    setSearchTerm(val);
  };
  useClickOutside(ref, () => setFocus(false));

  const autoFocusInput = component => {
    if (component && show) {
      component.focus();
    }
  };

  useEffect(() => {
    if (searchTerm === null) {
      setSearchStatus(false);
    }
  }, [searchTerm, setSearchStatus]);

  const keyPressListener = useCallback(
    event => {
      if (event.keyCode === 27) {
        toggleSearch();
      }
    },
    [toggleSearch]
  );

  useEffect(() => {
    if (show) {
      document.addEventListener("keydown", keyPressListener, false);
    }

    return () => {
      document.removeEventListener("keydown", keyPressListener, false);
    };
  }, [show, keyPressListener]);

  return (
    <SearchStore>
      <VisuallyHidden>
        <label htmlFor="search">{translate("search.title")}</label>
      </VisuallyHidden>
      <DummmyInput
        id="search"
        onFocus={() => setFocus(true)}
        type="text"
        placeholder={translate("search.title")}
        aria-label={translate("search.title")}
        onChange={triggerChange}
        {...{ collapse, focus }}
        sx={{
          py: [12, null, 1],
          pl: 2,
          pr: 5,
          width: "100%",
          bg: "muted",
          border: 0,
          ":focus": {
            bg: "muted",
          },
        }}
        ref={autoFocusInput}
      />
      {searchStatus === true && (
        <InstantSearch
          searchClient={searchClient}
          indexName={indices[0].name}
          onSearchStateChange={searchState => sendSearchTerm(searchState.query)}
          root={{ Root, props: { ref } }}
        >
          <Input value={query} />
          <Box
            show={query.length > 0 && focus}
            asGrid={hitsAsGrid}
            sx={{
              display: query.length >= 3 && focus ? `block` : `none`,
              position: "absolute",
              top: "100%",
              right: 0,
              left: 0,
              zIndex: 2,
              maxWidth: ["initial", null, null],
              maxHeight: "80vh",
              px: 2,
              overflowY: "scroll",
              overflowX: "hidden",
              bg: "white",
              boxShadow: "dropdown",
              "-webkit-overflow-scrolling": "touch",
              mark: {
                bg: "blueLighter",
                color: "primary",
              },
              ul: {
                m: 0,
                p: 0,
                listStyle: "none",
              },
            }}
          >
            {indices.map(({ name, title, hitComp }) => (
              <Index key={name} indexName={name}>
                <Flex sx={{ justifyContent: "space-between", mt: 3 }}>
                  {/*<Box>{title}</Box>*/}
                  <Stats />
                </Flex>
                <Results>
                  <Hits
                    hitComponent={hitComps[hitComp](
                      () => setFocus(false),
                      edges
                    )}
                  />
                </Results>
              </Index>
            ))}
          </Box>
        </InstantSearch>
      )}
    </SearchStore>
  );
};

export default SearchAlgoliaComponent;
