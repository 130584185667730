import { useStaticQuery, graphql } from "gatsby";

export const useContentfulLoginMenu = () => {
  const { allContentfulComponent } = useStaticQuery(
    graphql`
      query ContenfulLoginMenuQuery {
        allContentfulComponent(filter: { template: { eq: "LoginMenu" } }) {
          edges {
            node {
              id
              contentful_id
              __typename
              node_locale
              childContentfulComponentDataJsonNode {
                title
                pageType
                items {
                  title
                  subItems {
                    slug
                    title
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  const loginMenus = Array.isArray(allContentfulComponent.edges)
    ? allContentfulComponent.edges.map((item) => {
        return {
          locale: item.node.node_locale,
          menu: item.node.childContentfulComponentDataJsonNode,
        };
      })
    : [];
  return loginMenus;
};
