import { Link as GatsbyLink } from "gatsby";
import React, {useState} from "react";
import { Link as ThemeUiLink } from "theme-ui";
import useContentfulVariables from "../hooks/useContentfulVariables";
import { useSitePages } from "../hooks/useSitePages";
import {
  generateAnalyticsId,
  sendDamageReportEvent,
  sendLinkEvent,
} from "../utils/analytics";
import { CONTENTFUL_CONSTANTS } from "../utils/constants";
import {
  getUrlFromSlug,
  isValidEmail,
  isValidPhoneNumber,
  isValidUrl,
  matchAndReplace,
} from "../utils/utils";
import { ExternalLinkIcon } from "./Icon";

const { BASE_URL_OF } = CONTENTFUL_CONSTANTS;
const omaFenniaSites = [
  BASE_URL_OF,
  "varainhoito.fennia.fi/omafennia",
  "henki.fennia.fi/omafennia",
];

const exceptionsOmaFenniaSites = [
  "https://oma.fennia.fi/fsui/verkkopalvelusopimus",
];

const prefetchDisabledUrls = [
  "/avoimet-tyopaikat",
  "/lediga-jobb",
  "/toihin-uudistuvaan-fenniaan/avoimet-tyopaikat",
  "/jobba-pa-nya-fennia/lediga-jobb",
  "/tietosuoja-ja-kayttoehdot/evasteiden-kaytto",
  "/dataskydd-och-anvaendarvillkor/anvaendning-av-kakor",
];

const isString = (obj) => {
  return obj && (typeof obj === "string" || obj instanceof String);
};

const shouldDisablePreFetch = (href) => {
  if (isString(href) && prefetchDisabledUrls.includes(href)) {
    return true;
  }

  return false;
};

const isInternal = (href) => {
  if (isString(href)) {
    if (href.startsWith("/") && !href.startsWith("//")) {
      return true;
    }
    if (href.includes("www.fennia.fi")) {
      return true;
    }
  }
  return false;
};

const isOmaFenniaSite = (href) => {
  if (isString(href)) {
    return (
      omaFenniaSites.some((site) => href.includes(site)) &&
      exceptionsOmaFenniaSites.some((site) => !href.includes(site))
    );
  }

  return false;
};

const getLinkTarget = (href) => {
  return isInternal(href) || isOmaFenniaSite(href) ? "_self" : "_blank";
};

const IconExternal = () => (
  <ExternalLinkIcon
    sx={{ display: "inline", marginLeft: "4px" }}
    role="figure"
    aria-label="external link"
  />
);

const IconArrow = () => " \u2192";

const Link = ({
  children,
  link,
  target,
  analyticsAction,
  analyticsId,
  contentful_id,
  parentType,
  forwardRef,
  showArrow,
  ...other
}) => {
  const contentfulVariables = useContentfulVariables();

  const nodes = useSitePages();
  target = target && target.trim();

  if (target) {
    target = matchAndReplace(target, contentfulVariables);
  }

  const dataAnalyticsAction = analyticsAction || "click";
  const dataAnalyticsId = generateAnalyticsId(
    analyticsId,
    contentful_id,
    target || (other && other.href)
  );

  if (target && target === "etusivu") target = "/"; // fix for linking /etusivu
  // Complement Contentful asset links with protocol
  if (target && target.startsWith("//")) {
    target = `https:${target}`;
  }
  if (
    target &&
    isInternal(target) &&
    target.length > 1 &&
    target.endsWith("/")
  ) {
    target = target.substring(0, target.length - 1);
  }

  if (children && Array.isArray(children)) {
    children = children.map((child) => {
      return matchAndReplace(child, contentfulVariables);
    });
  } else if (children) {
    children = matchAndReplace(children, contentfulVariables);
  }

  if (isValidUrl(target)) {
    return (
      <ThemeUiLink
        href={target}
        {...other}
        data-analytics-id={dataAnalyticsId}
        data-analytics-action={dataAnalyticsAction}
        target={getLinkTarget(target)}
      >
        {children}

        {!isInternal(target) && parentType !== "liftUpLinks" ? (
          <IconExternal />
        ) : (
          showArrow && <IconArrow />
        )}
      </ThemeUiLink>
    );
  }

  if (isValidEmail(target)) {
    return (
      <ThemeUiLink
        href={`mailto:${target}`}
        {...other}
        data-analytics-id={dataAnalyticsId}
        data-analytics-action={dataAnalyticsAction}
      >
        {children}
        {showArrow && <IconArrow />}
      </ThemeUiLink>
    );
  }

  if (isValidPhoneNumber(target)) {
    const telephoneUri = (
      target.startsWith("tel:") ? target : `tel:${target}`
    ).replace(/\s/g, "");
    return (
      <ThemeUiLink
        href={telephoneUri}
        {...other}
        data-analytics-id={dataAnalyticsId}
        data-analytics-action={dataAnalyticsAction}
      >
        {children}
        {showArrow && <IconArrow />}
      </ThemeUiLink>
    );
  }

  if (target) {
    // find parent
    target = getUrlFromSlug(target, nodes);
    if (typeof target === "object") {
      target = target.link;
    }

    // Disable gatsby prefetch for certain pages so external scripts will execute correctly
    if (shouldDisablePreFetch(target)) {
      return (
        <ThemeUiLink
          {...other}
          href={target}
          data-analytics-id={dataAnalyticsId}
          data-analytics-action={dataAnalyticsAction}
          target={getLinkTarget(target)}
          ref={forwardRef}
        >
          {children}
          {!isInternal(target) ? <IconExternal /> : showArrow && <IconArrow />}
        </ThemeUiLink>
      );
    }

    return (
      <ThemeUiLink
        as={GatsbyLink}
        {...other}
        to={target}
        data-analytics-id={dataAnalyticsId}
        data-analytics-action={dataAnalyticsAction}
        target={getLinkTarget(target)}
        ref={forwardRef}
      >
        {children}
        {!isInternal(target) ? <IconExternal /> : showArrow && <IconArrow />}
      </ThemeUiLink>
    );
  }

  // If nothing else works link to front page
  const [isHoveringFrontPageLink, setIsHoveringFrontPageLink] = useState(false);
  return (
    <GatsbyLink
      style={{textDecoration: "none", opacity: isHoveringFrontPageLink ? "0.8": "1"}}
      onMouseEnter={()=>setIsHoveringFrontPageLink(true)}
      onMouseLeave={()=>setIsHoveringFrontPageLink(false)}
      to={"/"}
      {...other}
      data-analytics-id={dataAnalyticsId}
      data-analytics-action={dataAnalyticsAction}
    >
      {children}
      {showArrow && <IconArrow />}
    </GatsbyLink>
  );
};

export default Link;
