import { useStaticQuery, graphql } from "gatsby";

export const useContenfulFooterMenu = () => {
  const { allContentfulComponent } = useStaticQuery(
    graphql`
      query ContentfulFooterMenuQuery {
        allContentfulComponent(filter: { template: {eq: "FooterMenu"} }) {
          edges {
            node {
              node_locale
              childContentfulComponentDataJsonNode {
                menu {
                  slug
                  title
                }
                contactInformation {
                  address
                  phone
                }
                subItems {
                  title,
                  slug
                }
                pageType
              }
            }
          }
        }
      }
    `
  );
  const menus = Array.isArray(allContentfulComponent.edges) ? allContentfulComponent.edges.map((item) => {
    return  {
      ...item.node.childContentfulComponentDataJsonNode,
      locale: item.node.node_locale
    };
  }) : [];
  return menus;
};
