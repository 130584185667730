/** @jsx jsx */
import { jsx, Button, Box } from "theme-ui";

const AccordionIcon = () => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.104 11"
    sx={{
      display: "inline-block",
      ml: 1,
      transition: "transform .2s ease",
      transformOrigin: "center",
      width: [24, null, 16],
      height: [16, null, 11],
      pointerEvents: "none",
    }}
  >
    <path
      d="M8.008,14.49a1.533,1.533,0,0,1-1.152-.521L.315,6.536A1.231,1.231,0,1,1,2.164,4.91l5.719,6.5a.167.167,0,0,0,.25,0l5.719-6.5A1.231,1.231,0,1,1,15.7,6.536L9.163,13.969a1.54,1.54,0,0,1-1.154.522Z"
      transform="translate(0.492 -3.991)"
      fill="#333"
      stroke="#fff"
      strokeWidth="1"
    />
  </Box>
);

const Dropdown = ({
  title,
  showAccordion,
  children,
  buttonStyle,
  isOpen,
  isHovered,
  forwardRef,
  ...rest
}) => {
  const accordionStyle =
    isOpen || isHovered
      ? {
          "> svg": { transform: [null, null, "rotate(180deg)"] },
        }
      : null;
  return (
    <Box
      sx={{
        position: "relative",
        width: ["100%", null, "auto"],
        ml: [0, null, 2],
        borderBottomWidth: ["1px", null, 0],
        borderBottomStyle: ["solid", null, "none"],
        borderBottomColor: "lightGrey",
      }}
      ref={forwardRef}
      {...rest}
    >
      <Button
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 0,
          py: [2, null, 0],
          bg: "transparent",
          fontSize: [4, null, 1],
          cursor: "pointer",
          textAlign: "left",
          width: ["100%", null, "auto"],
          ...buttonStyle,
          ":hover": {
            color: "text",
            opacity: 0.8,
            bg: "transparent",
          },
          ...accordionStyle,
        }}
      >
        {title}
        {showAccordion && <AccordionIcon />}
      </Button>
      {children}
    </Box>
  );
};

export default Dropdown;
