import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const { allContentfulComponent } = useStaticQuery(
    graphql`
      query ContentfulVariablesQuery {
        allContentfulComponent(
          filter: {
            template: { eq: "Variables" }
            node_locale: { eq: "fi-FI" }
          }
        ) {
          edges {
            node {
              id
              contentful_id
              __typename
              data {
                variables {
                  key
                  value
                }
              }
            }
          }
        }
      }
    `
  );

  const arrayToObject = (arr) => {
    return Object.assign(
      {},
      ...arr.map((item) => {
        return { [item.key]: item.value };
      })
    );
  };

  return Array.isArray(allContentfulComponent.edges) &&
    Array.isArray(allContentfulComponent.edges[0].node.data.variables)
    ? arrayToObject(allContentfulComponent.edges[0].node.data.variables)
    : {};
};
