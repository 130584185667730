/** @jsx jsx */
// This component is used to display icon-links to Fennia's social media channels. It is used in the footer. Urls are fetched from site metadata (gatsby-config)

import React from 'react';
import { jsx, Box } from "theme-ui";
import { SvgIcon } from '@material-ui/core';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    someIcon: {
        color:'white'
    }
  }));

export const SomeLinks = () => {

    const { social } = useSiteMetadata();

    const classes = useStyles();

    const someArray = [
        {
            name: "Facebook",
            svg: (<svg><path d="M14.23,3.48h0s-3.29-1.2-3.29,1.25V6.57h3.21l-.29,3H10.94V20H7V9.62H5.09v-3H7V3.36S7,.89,10.2.18A9.39,9.39,0,0,1,14.67.4Z" /></svg>),
            someUrl: social.facebook
        },
        {
            name: "Twitter",
            svg: (<svg><path d="M21,3.36A4.84,4.84,0,0,0,23.14.6,11.37,11.37,0,0,1,20,1.76,4.58,4.58,0,0,0,16.51.22,4.73,4.73,0,0,0,13,1.68a4.87,4.87,0,0,0-1.43,3.53,3.71,3.71,0,0,0,.16,1.11A13,13,0,0,1,1.66,1.12,5.13,5.13,0,0,0,1,3.62,4.86,4.86,0,0,0,3.16,7.81,5.26,5.26,0,0,1,1,7.17a5.08,5.08,0,0,0,1.08,3.24,4.76,4.76,0,0,0,2.8,1.74,5.09,5.09,0,0,1-1.3.16,2.37,2.37,0,0,1-.88-.11,4.86,4.86,0,0,0,4.56,3.5,9.37,9.37,0,0,1-6.06,2.12A8.51,8.51,0,0,1,0,17.77,13.25,13.25,0,0,0,7.51,20,13.05,13.05,0,0,0,15,17.8a13.57,13.57,0,0,0,4.81-5.39,14.82,14.82,0,0,0,1.55-6.62V5.15a10.3,10.3,0,0,0,2.44-2.6A10.61,10.61,0,0,1,21,3.36Z" /></svg>),
            someUrl: social.twitter
        },
        {
            name: "Instagram",
            svg: (<svg><path d="M16,0H6A5,5,0,0,0,1,5V15a5,5,0,0,0,5,5H16a5,5,0,0,0,5-5V5A5,5,0,0,0,16,0Zm3,15a3,3,0,0,1-3,3H6a3,3,0,0,1-3-3V5A3,3,0,0,1,6,2H16a3,3,0,0,1,3,3Z" /><path d="M11,5.5A4.5,4.5,0,1,0,15.5,10,4.51,4.51,0,0,0,11,5.5Zm0,7A2.5,2.5,0,1,1,13.5,10,2.5,2.5,0,0,1,11,12.5Z" /><circle cx="16.25" cy="4.75" r="1" /></svg>),
            someUrl: social.instagram
        },
        {
            name: "YouTube",
            svg: (<svg><path d="M21.82,4.82C21.7,3,20.85.7,19.09.62a85.77,85.77,0,0,0-15.7,0C1.84.84.78,3.24.66,5.06a64.64,64.64,0,0,0,0,9.84c.12,1.82,1.15,4.24,2.73,4.49a91.45,91.45,0,0,0,15.7,0c1.52-.34,2.61-2.67,2.73-4.49A70.65,70.65,0,0,0,21.82,4.82ZM9.08,15.47v-11L15.54,10Z" /></svg>),
            someUrl: social.youtube
        },
        {
            name: "LinkedIn",
            svg: (<svg><path d="M19.84,19.85H15.63v-9.1c0-1.06-.3-1.81-1.61-1.81a2.52,2.52,0,0,0-2.59,1.81v9.1H7.22V5.13h4.21V6.54a7,7,0,0,1,4.2-1.4c1.37,0,4.21.81,4.21,5.74Z" /><path d="M3.15,4.23h0a2,2,0,1,1,2-2A2,2,0,0,1,3.15,4.23Z" /><polygon points="5.12 19.85 5.12 19.85 0.91 19.85 0.91 5.13 5.12 5.13 5.12 19.85" /></svg>),
            someUrl: social.linkedIn
        }
    ];


    return (
        <Box 
        sx={{ 
            display: 'flex',
            mt: [20, null, null, 0],
            width: 160,
            justifyContent: 'space-between'
           
        }}>
            {someArray.map(function (item, i) {
                
                return (
                    <a href={item.someUrl} key={i} target="_blank" rel="noopener noreferrer">
                    <SvgIcon key={i} fontSize={'medium'} component={'svg'} viewBox={"0 0 25 25"} className={classes.someIcon} titleAccess={'Fennia ' + item.name}>
                        {item.svg}
                    </SvgIcon>
                    </a>
                );
            })}
        </Box>

    );
};


