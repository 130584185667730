/** @jsx jsx */
import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { Flex, jsx, Spinner, Themed } from "theme-ui";
//import FeedbackModal, { FeedbackModalTypes } from "../components/FeedbackModal";
import { LocalizationContext } from "../context/LocalizationContext";
import { NotificationContext } from "../context/NotificationContext";
import { PageTypeContext } from "../context/PageTypeContext";
import { PathContext } from "../context/PathContext";
import { useContentfulFeedback } from "../hooks/useContentfulFeedback";
import { sendCustomerSwitch, sendLayoutEvent } from "../utils/analytics";
import { parseJsonValues } from "../utils/utils";
import { isBrowser } from "../utils/utils";
import Footer from "./Footer";
import Header from "./Header";
import Notification from "./Notification";

export const PageTypes = {
  PrivateCustomer: "Private Customer",
  CompanyCustomer: "Company Customer",
  Concern: "Concern",
};

const Skip = styled.a`
  // font-family: ${(props) => props.theme.fonts.body};
  padding: 0 1rem;
  line-height: 60px;
  background: #2867cf;
  color: white;
  z-index: 101;
  position: fixed;
  top: -100%;
  &:hover {
    text-decoration: underline;
  }
  &:focus,
  &:active,
  &:hover {
    top: 0;
  }
`;

const OPTIMIZE_CHECK_DELAY = 300;
const OPTIMIZE_CHECK_INTERVAL = 50;

const SURVEY_POPUP_THRESHOLD = 90 * 24 * 60 * 60 * 1000; // 90 days as milliseconds

const getPageType = (slug, savedPageType, contentfulPageType) => {
  if (contentfulPageType === PageTypes.Concern || slug === "") {
    return contentfulPageType;
  }

  return savedPageType || contentfulPageType || PageTypes.PrivateCustomer;
};

const Layout = ({
  pageType: contentfulPageType,
  children,
  pageContext,
  enableAbTesting,
  showNpsPopup,
  showLogOutSurveys,
}) => {
  const { language, getLocale, translate, setLanguage } =
    useContext(LocalizationContext);
  const { notification } = useContext(NotificationContext);
  const {
    language: pageContextLanguage,
    slug,
    node_locale,
  } = pageContext || {};
  const locale = node_locale || getLocale();
  const pageLanguage = pageContextLanguage || language;
  useEffect(() => {
    if (pageContextLanguage !== language) setLanguage(pageContextLanguage);
  }, []);
  const { pageType: savedPageType, setPageType } = useContext(PageTypeContext);
  const pageType = getPageType(slug, savedPageType, contentfulPageType);
  const { visitedPaths, addPath } = useContext(PathContext);
  const [loading, setLoading] = useState(
    enableAbTesting && !visitedPaths.includes(`/${slug}`)
  );
  const [menu, toggleOpen] = useState(false);
  const [showMobileLoginMenu, setShowMobileLoginMenu] = useState(false);

  const localizedFeedbackConfigs = useContentfulFeedback();
  const { siteSurveyKT, siteSurveyYR } = localizedFeedbackConfigs.find(
    (config) => config.locale === locale
  );
  const [feedback, openFeedback] = useState(false);
  const [surveyType, setSurveyType] = useState();

  const getPageSection = () => {
    if (pageType === "Company Customer") {
      return "yr";
    } else {
      return "ha";
    }
  };

  useEffect(() => {
    const pageSection = getPageSection();
    localStorage.setItem("pageSection", pageSection);
    sendLayoutEvent(
      (pageContext && pageContext.pageType) || "unknown",
      pageLanguage,
      pageSection
    );
  }, [pageContext, pageLanguage, pageType]);

  useEffect(() => {
    const sessionPageType = sessionStorage.getItem("preferredPageType");
    if (sessionPageType && sessionPageType !== pageType) {
      setPageType(sessionPageType);
    }
  }, []);

  useEffect(() => {
    if (!enableAbTesting) return; // Don't try check changes in DOM if A/B testing is disabled

    if (isBrowser && !visitedPaths.includes(window.location.pathname)) {
      addPath(window.location.pathname);
    }
    setTimeout(() => {
      if (loading) {
        const checkOptimizeChanges = () => {
          const isLoading = document.querySelector("loading-style");
          if (!isLoading) {
            clearInterval(optimizeCheck);
            setLoading(false);
            return;
          }
        };

        const optimizeCheck = setInterval(
          checkOptimizeChanges,
          OPTIMIZE_CHECK_INTERVAL
        );
      }
    }, OPTIMIZE_CHECK_DELAY);
  }, [loading, addPath, slug, visitedPaths, enableAbTesting]);

  
  /* 
  // Moving to Qualtrics removing  Servicepal
  useEffect(() => {
    let timeOutId;

    //FEFI-201 && FEFI-369
    const urlParams = parseJsonValues(location.search.substring(1).split("&"));

    // Show NPS popups for OF logouts
    if (
      showLogOutSurveys &&
      (urlParams["OFlogoutKT"] ||
        urlParams["OFlogoutYR"] ||
        urlParams["HFOFlogout"])
    ) {
      if (urlParams["OFlogoutKT"]) {
        setSurveyType(FeedbackModalTypes.LOGOUT_SURVEY_KT);
      } else if (urlParams["OFlogoutYR"]) {
        setSurveyType(FeedbackModalTypes.LOGOUT_SURVEY_YR);
      } else {
        setSurveyType(FeedbackModalTypes.LOGOUT_SURVEY_HFOF);
      }

      const getLogoutFeedbackFromLocalStorage = () => {
        try {
          return localStorage.getItem("logoutFeedback");
        } catch (error) {
          return undefined;
        }
      };
      const feedbackTimeStamp = getLogoutFeedbackFromLocalStorage();
      if (feedbackTimeStamp) {
        try {
          const timestamp = +feedbackTimeStamp;
          if (Date.now() >= timestamp + SURVEY_POPUP_THRESHOLD) {
            openFeedback(true);
          }
        } catch (err) {
          console.error(err);
          // openFeedback(true)?
        }
      } else {
        openFeedback(true);
      }
    } else if (
      showNpsPopup &&
      (pageType === PageTypes.CompanyCustomer ||
        pageType === PageTypes.PrivateCustomer)
    ) {
      // Show general NPS popups for random people
      const surveyType =
        pageType === PageTypes.CompanyCustomer ? siteSurveyYR : siteSurveyKT;
      const feedbackModalType =
        pageType === PageTypes.CompanyCustomer
          ? FeedbackModalTypes.SITE_SURVEY_YR
          : FeedbackModalTypes.SITE_SURVEY_KT;
      const { displayFrequencyPercent, timeDelaySeconds } = surveyType;
      setSurveyType(feedbackModalType);

      const displayDelayedFeedbackPopupForRandomUser = () => {
        // Feedback lottery for every Nth user
        const lottery = Math.floor(Math.random() * displayFrequencyPercent);
        if (lottery === 0) {
          timeOutId = setTimeout(() => {
            openFeedback(true);
          }, timeDelaySeconds * 1000);
        }
      };

      const siteFeedbackRequested = localStorage.getItem("siteFeedback");
      try {
        const siteFeedbackRequestTimestamp = +siteFeedbackRequested;
        if (
          !siteFeedbackRequestTimestamp ||
          (siteFeedbackRequestTimestamp &&
            Date.now() >= siteFeedbackRequestTimestamp + SURVEY_POPUP_THRESHOLD)
        ) {
          displayDelayedFeedbackPopupForRandomUser();
        }
      } catch (err) {
        displayDelayedFeedbackPopupForRandomUser();
      }

      window.displayPopup = () => {
        openFeedback(true);
      };
    }

    return () => {
      clearTimeout(timeOutId);
    };
  }, [openFeedback, siteSurveyKT, siteSurveyYR, pageType]);
  */

  const menuTypeSelectionHandler = (event) => {
    let pageTypeToSave;
    switch (pageType) {
      case PageTypes.CompanyCustomer:
      case PageTypes.Concern:
        pageTypeToSave = PageTypes.PrivateCustomer;
        break;
      case PageTypes.PrivateCustomer:
      default:
        pageTypeToSave = PageTypes.CompanyCustomer;
    }
    sendCustomerSwitch(event && event.target && event.target.pathname);
    sessionStorage.setItem("preferredPageType", pageTypeToSave);
    setPageType(pageTypeToSave);
  };

  return (
    <Themed.root
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        variant: "layout.root",
        margin: "-8px", // TODO: temporary user agent default margin fix
      }}
    >
      <Skip
        sx={{
          "@media print": {
            display: "none",
          },
        }}
        href="#content"
        id="skip-navigation"
      >
        {translate("layout.skipToContent")}
      </Skip>

      <Header
        pageType={pageType}
        menuTypeSelectionHandler={menuTypeSelectionHandler}
        locale={locale}
        language={pageLanguage}
        menu={menu}
        toggleOpen={toggleOpen}
        showMobileLoginMenu={showMobileLoginMenu}
        setShowMobileLoginMenu={setShowMobileLoginMenu}
        slug={slug}
      />
      {notification && <Notification notification={notification} />}
      
      {/*
      {feedback && (
        <FeedbackModal
          state={openFeedback}
          type={surveyType}
          pageContext={pageContext}
        />
      )}
      */}

      {loading && (
        <Spinner
          title={translate("loading")}
          size="64"
          role="status"
          sx={{
            position: "fixed",
            marginTop: "15em",
            width: "100%",
            zIndex: 99,
          }}
        />
      )}
      <Flex
        as="main"
        id="main"
        aria-live="polite"
        aria-busy={loading}
        sx={{
          width: "100%",
          flexDirection: "column",
          flex: "1 1 auto",
          variant: "layout.main",
          opacity: loading ? 0 : 1,
        }}
      >
        {children}
      </Flex>
      <Footer
        pageType={pageType}
        menuTypeSelectionHandler={menuTypeSelectionHandler}
        locale={locale}
        language={pageLanguage}
      />
    </Themed.root>
  );
};

export default Layout;
