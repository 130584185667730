function getDataLayer() {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
}

export const sendSearchTerm = (searchTerm) => {
  if (searchTerm) {
    getDataLayer().push({
      event: "search-term",
      searchTerm,
    });
  }
};

export const sendSearchTermClick = (target) => {
  getDataLayer().push({
    event: "search-term-click",
    pageUrl: target,
  });
};

export const sendLayoutEvent = (pageType, language, pageSection) => {
  if (pageType) {
    getDataLayer().push({
      event: "page-load",
      pageType,
      pageLanguage: language,
      pageSection: pageSection,
    });
  }
};

const allowedFields = [
  "about",
  "damageType",
  "contactType",
  "flat",
  "flatArea",
  "flatMaterial",
  "separate",
  "separateArea",
  "separateYear",
  "separateMaterial",
  "holiday",
  "holidayArea",
  "holidayYear",
  "holidayMaterial",
  "adultHealthInsurance",
  "adultLifeInsurance",
  "childHealthInsurance",
  "travelInsurance",
  "vehicleNumber",
  "boatNumber",
  "petInsurance",
  "office",
  "contact",
  "danskeClient",
  "employees",
];
export const sendFormSubmitEvent = (type, language, values) => {
  const allowedValues = {};
  for (const [field, value] of Object.entries(values)) {
    if (typeof value === "boolean" || value) {
      // IMPORTANT: any data sent from forms to dataLayer should NEVER include any PII (name, SSN, location, zip etc.)!
      allowedValues[field] = allowedFields.includes(field) ? value : !!value;
    }
  }
  const event = {
    event: "fe-form-submit",
    formName: type,
    formLanguage: language,
    pageUrl: window && window.location && window.location.pathname,
  };
  event[type] = allowedValues;
  getDataLayer().push(event);
};

export const sendLanguageSwitch = (language, prevLanguage) => {
  getDataLayer().push({
    event: "fe-language-switch",
    oldLanguage: prevLanguage,
    newLanguage: language,
  });
};

export const sendCustomerSwitch = (newValue) => {
  getDataLayer().push({
    event: "fe-customer-switch",
    oldCustomer: window && window.location && window.location.pathname,
    newCustomer: newValue,
  });
};

export const sendCaptchaEvent = (successful) => {
  getDataLayer().push({
    event: successful ? "fe-captcha-ok" : "fe-captcha-fail",
  });
};

export const sendInsuranceDropdownEvent = (name, to, language) => {
  getDataLayer().push({
    event: "fe-insurance-dropdown-event",
    name,
    to,
    from: window && window.location && window.location.pathname,
    language,
  });
};

export const send404Event = () => {
  getDataLayer().push({
    event: "fe-page-404",
    location: window && window.location && window.location.pathname,
  });
};

export const sendSurveyPopupAppearEvent = (language, popupName) => {
  getDataLayer().push({
    event: "fe-popup-appear",
    popupName: popupName,
    pageUrl: window && window.location && window.location.pathname,
    popupLanguage: language,
  });
};

export const sendSurveyPopupClosedEvent = (language, popupName) => {
  getDataLayer().push({
    event: "fe-popup-close",
    popupName: popupName,
    pageUrl: window && window.location && window.location.pathname,
    popupLanguage: language,
  });
};

export const sendSurveyPopupOpenEvent = (language, surveyUrl, popupName) => {
  getDataLayer().push({
    event: "fe-popup-open",
    popupName: popupName,
    pageUrl: window && window.location && window.location.pathname,
    popupLanguage: language,
    [popupName]: {
      to: surveyUrl,
    },
  });
};

export const sendLikeEvent = (type) => {
  getDataLayer().push({
    event: "fe-page-like",
    type: type,
    pageUrl: window && window.location && window.location.pathname,
  });
};

export const sendOptimizeActivate = () => {
  getDataLayer().push({
    event: "optimize.activate",
  });
};

export const sendLinkEvent = (name) => {
  getDataLayer().push({
    event: "fe-lift-up-links",
    name: name,
  });
};

export const sendDamageReportEvent = (name) => {
  getDataLayer().push({
    event: "fe-damage-report",
    name: name,
  });
};

let counters = {};

export const generateAnalyticsId = (analyticsId, contentful_id, target) => {
  if (analyticsId) return analyticsId;
  // if no analytics id setup, then generate one
  let id = "unknown";
  target = typeof target === "object" && target.link ? target.link : target;
  if (contentful_id) {
    // id fix
    id = contentful_id.startsWith("c")
      ? contentful_id.substring(1)
      : contentful_id;
  } else if (typeof target === "string" || target instanceof String) {
    id = target.replace(/[^A-Za-z\-_\d]/g, "");
  }
  let count = counters[id] || 1;
  counters[id] = count + 1;
  return id + "-" + count;
};
