/** @jsx jsx */
import { jsx, Box, Button } from "theme-ui";
import VisuallyHidden from "../VisuallyHidden";
import { useContext } from "react";
import { LocalizationContext } from "../../context/LocalizationContext";

const NavBar = (props) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "12px",
        height: 2,
        backgroundColor: "text",
        width: "100%",
        transition: "all .25s ease",

        "&:nth-of-type(2)": {
          transform: props.close ? "rotate(0deg)" : "rotate(45deg)",
        },
        "&:nth-of-type(3)": {
          transform: props.close ? "rotate(0deg)" : "rotate(-45deg)",
        },
        "&:first-of-type": {
          top: props.close ? "5px" : "12px",
          width: props.close ? "100%" : 0,
          left: props.close ? 0 : "50%",
        },
        "&:last-of-type": {
          top: props.close ? 19 : "12px",
          width: props.close ? "100%" : 0,
          left: props.close ? 0 : "50%",
        },
      }}
    />
  );
};

const MenuToggle = (props) => {
  const { translate } = useContext(LocalizationContext);
  const showMenuIcon = props.showMobileLoginMenu || !props.close;
  return (
    <Button
      variant="plain"
      {...props}
      sx={{
        display: ["flex", null, "none"],
        justifyContent: "center",
        p: 0,
      }}
    >
      <Box
        sx={{
          position: "relative",
          cursor: "pointer",
          width: 24,
          height: 24,
          transition: ".4 ease-in-out",
        }}
      >
        <NavBar close={showMenuIcon} />
        <NavBar close={showMenuIcon} />
        <NavBar close={showMenuIcon} />
        <NavBar close={showMenuIcon} />
      </Box>
      <VisuallyHidden>{translate("menu.menu")}</VisuallyHidden>
    </Button>
  );
};

export default MenuToggle;
