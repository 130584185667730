/** @jsx jsx */
import dayjs from "dayjs";
import { useContext, useEffect, useState, useRef } from "react";
import { Box, Container, Flex, jsx, Themed } from "theme-ui";
import { FaultNotificationContext } from "../context/FaultNotificationContext";
import { LocalizationContext } from "../context/LocalizationContext";
import { SearchContext } from "../context/SearchContext";
import { useSitePages } from "../hooks/useSitePages";
import * as api from "../utils/api";
import {
  getFaultNotificationsFromSessionStorage,
  getClosedNotificationsToSessionStorage,
  getLocalizedUrlAndTitleFromSlug,
  saveFaultNotificationsToSessionStorage,
} from "../utils/utils";
import FaultNotifications from "./FaultNotifications";
import { PageTypes } from "./Layout";
import Link from "./Link";
import Logo from "./Logo";
import MenuToggle from "./menu/MenuToggle";
import MenuWrapper from "./menu/MenuWrapper";
import Preview from "../components/Preview";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

const FAULT_NOTIFICATION_POLL_FIVE_MIN_INTERVAL = 300000;

const Header = ({
  siteTitle,
  pageType,
  menuTypeSelectionHandler,
  locale,
  language,
  menu,
  toggleOpen,
  showMobileLoginMenu,
  setShowMobileLoginMenu,
  height,
  slug,
}) => {
  const { translate } = useContext(LocalizationContext);
  const { setSearchTerm } = useContext(SearchContext);
  const {
    faultNotifications,
    setFaultNotifications,
    fetchDateTime,
    setFetchDateTime,
  } = useContext(FaultNotificationContext);

  const pages = useSitePages();

  const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing");
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleFirstTab);
    const savedFaultNotifications = getFaultNotificationsFromSessionStorage();
    const lastFaultNotificationCheck = savedFaultNotifications
      ? savedFaultNotifications["timestamp"]
      : 0;

    if (
      !savedFaultNotifications ||
      lastFaultNotificationCheck <
        Date.now() - FAULT_NOTIFICATION_POLL_FIVE_MIN_INTERVAL
    ) {
      (async () => {
        try {
          const { date, validNotifications } =
            await api.getFaultNotifications();
          const fetchDateTime = dayjs(date).tz("Europe/Helsinki");
          const removedNotifications = getClosedNotificationsToSessionStorage();

          const updatedValidNotifications = validNotifications.map(
            (notification) => {
              return {
                ...notification,
                expanded: false,
              };
            }
          );

          const removeAlreadyClosedNotifications =
            removedNotifications &&
            updatedValidNotifications.filter(
              (notification) => !removedNotifications.includes(notification.id)
            );
          setFaultNotifications(
            removeAlreadyClosedNotifications || updatedValidNotifications
          );
          setFetchDateTime(fetchDateTime);
          saveFaultNotificationsToSessionStorage(
            removeAlreadyClosedNotifications || updatedValidNotifications,
            fetchDateTime
          );
        } catch (error) {
          console.error(error);
        }
      })();
    } else {
      setFaultNotifications(savedFaultNotifications["faultNotifications"]);
      setFetchDateTime(savedFaultNotifications["timestamp"]);
    }
  }, [setFaultNotifications, setFetchDateTime]);

  const { slug: localizedFrontPageSlug } = getLocalizedUrlAndTitleFromSlug(
    "/",
    language,
    pages
  );

  const { slug: localizedCompanyFrontPageSlug } =
    getLocalizedUrlAndTitleFromSlug("/etusivu-yritys", language, pages);

  const { slug: localizedOmaFenniaPageSlug } = getLocalizedUrlAndTitleFromSlug(
    "/oma-fennia",
    language,
    pages
  );

  const frontPageSlug =
    pageType === PageTypes.CompanyCustomer
      ? localizedCompanyFrontPageSlug
      : localizedFrontPageSlug;

  const handleMobileLoginMenu = () => {
    toggleOpen(!showMobileLoginMenu);
    setShowMobileLoginMenu((prevState) => {
      return !prevState;
    });
  };

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [headerSx, setHeaderSx] = useState({
    position: "sticky",
    top: "0",
    transition: "unset",
    left: 0,
    py: [20, null, 3],
    zIndex: 99,
    bg: "white",
    "@media print": {
      position: "static",
    },
  });

  const notificationsRef = useRef(null);
  const headerRef = useRef(null);
  const [showNotifications, setShowNotifications] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const notificationsHeight = notificationsRef.current
        ? notificationsRef.current.offsetHeight
        : 0;
      const headerHeight = headerRef.current
        ? headerRef.current.offsetHeight
        : 0;
      const totalHeight = notificationsHeight + headerHeight;
      if (scrollTop > lastScrollTop && !menu && !showMobileLoginMenu) {
        setShowNotifications(false);
        setHeaderSx((prevSx) => ({
          ...prevSx,
          top: `-${totalHeight}px`,
          transition: "top 1s cubic-bezier(.42,0,.58,1)",
        }));
      } else if (!menu && !showMobileLoginMenu) {
        if (scrollTop === 0) {
          setShowNotifications(true);
        }
        setHeaderSx((prevSx) => ({
          ...prevSx,
          top: 0,
          transition: "top 0.5s cubic-bezier(.42,0,.58,1)",
        }));
      }
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop, menu, showMobileLoginMenu, showNotifications]);

  return (
    <header ref={headerRef} sx={headerSx}>
      <div ref={notificationsRef}>
        {showNotifications && Array.isArray(faultNotifications) && (
          <FaultNotifications
            faultNotifications={faultNotifications}
            locale={locale}
            fetchDateTime={fetchDateTime}
            menu={menu}
            showMobileLoginMenu={showMobileLoginMenu}
            slug={slug}
          />
        )}
      </div>
      <Container
        as={Flex}
        sx={{
          justifyContent: "space-between",
          alignItems: ["center", null, "flex-end"],
        }}
      >
        <Link
          target={frontPageSlug}
          sx={{ flex: "none" }}
          aria-label={translate("logo.alt", null, language)}
        >
          <Logo
            sx={{
              width: [80, null, 120],
              height: [23, null, 34],
              mb: [0, null, 1],
            }}
            title={siteTitle}
          />
        </Link>
        {(process.env.GATSBY_ACTIVE_ENV === "preview" ||
          process.env.GATSBY_ACTIVE_ENV === "preview-local") && <Preview />}
        <MenuWrapper
          menuIsVisible={menu}
          toggleOpen={toggleOpen}
          pageType={pageType}
          localizedFrontPageSlug={localizedFrontPageSlug}
          localizedCompanyFrontPageSlug={localizedCompanyFrontPageSlug}
          menuTypeSelectionHandler={menuTypeSelectionHandler}
          locale={locale}
          language={language}
          showMobileLoginMenu={showMobileLoginMenu}
        />
        <Flex
          sx={{
            "@media print": {
              display: "none",
            },
          }}
        >
          <Box
            sx={{
              display: ["block", null, "none"],
              mr: 3,
            }}
            onClick={handleMobileLoginMenu}
          >
            <Themed.h4
              sx={{ color: "primary", mt: 0, mb: 0, cursor: "pointer" }}
            >
              {translate("menu.login", null, language)}
            </Themed.h4>
          </Box>
          <MenuToggle
            close={menu}
            onClick={(e) => {
              // clear search results if menu is closed
              setSearchTerm(null);
              toggleOpen(!menu);
              setShowMobileLoginMenu(false);
              if (menu) return;
            }}
            showMobileLoginMenu={showMobileLoginMenu}
          />
        </Flex>
      </Container>
    </header>
  );
};

export default Header;
