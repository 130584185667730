/** @jsx jsx */
import { jsx, Box } from "theme-ui";

const Logo = props => (
  <Box
    as="svg"
    width="160"
    height="45.341"
    viewBox="0 0 160 45.341"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    sx={{ display: "block", color: "primary" }}
  >
    <path
      d="M22.327,0V5.29c-4.534,0-8.244,4.465-8.244,10.03H8.244C8.244,6.87,14.564,0,22.327,0m0,14.7v5.29c-4.534,0-8.244,4.465-8.244,10.03v14.7H8.244v-14.7c0-5.5-3.71-10.03-8.244-10.03V14.7a13.814,13.814,0,0,1,11.2,5.977A13.6,13.6,0,0,1,22.327,14.7m15.32,5.29c3.435,0,6.389,2.542,7.626,6.183H30.021c1.237-3.641,4.191-6.183,7.626-6.183M51.662,31.464a10.146,10.146,0,0,0,.069-1.443c0-8.45-6.32-15.32-14.083-15.32s-14.083,6.87-14.083,15.32a15.34,15.34,0,0,0,27.274,9.618l-4.053-4.053a9.384,9.384,0,0,1-7.832,4.465,9.537,9.537,0,0,1-9.274-8.519H51.662Zm32.426-1.443v14.7H78.179v-14.7c0-5.5-3.71-10.03-8.244-10.03a9.335,9.335,0,0,0-8.244,5.152V44.654H55.784V15.32h5.908v2.267A13.5,13.5,0,0,1,69.936,14.7c7.832,0,14.152,6.87,14.152,15.32m32.907,0v14.7h-5.908v-14.7c0-5.5-3.71-10.03-8.244-10.03A9.335,9.335,0,0,0,94.6,25.144V44.654H88.69V15.32H94.6v2.267a13.5,13.5,0,0,1,8.244-2.885c7.832,0,14.152,6.87,14.152,15.32m10.648,14.7h-5.908V15.32h5.908Zm18.205-4.74c-4.534,0-8.244-4.465-8.244-10.03,0-5.5,3.71-10.03,8.244-10.03a9.335,9.335,0,0,1,8.244,5.152v9.687a9.353,9.353,0,0,1-8.244,5.221m8.244-24.663v2.267a13.5,13.5,0,0,0-8.244-2.885c-7.763,0-14.083,6.87-14.083,15.32s6.32,15.32,14.083,15.32a13.086,13.086,0,0,0,8.244-2.885v2.267H160V15.32Z"
      fill="currentColor"
    />
  </Box>
);

export default Logo;
