/** @jsx jsx */
import React, { useContext, useEffect, useRef } from "react";
import { jsx } from "theme-ui";
import { LocalizationContext } from "../context/LocalizationContext";
import { useSitePages } from "../hooks/useSitePages";
import { sendLanguageSwitch } from "../utils/analytics";
import { getLocalizedUrlAndTitleFromSlug } from "../utils/utils";
import Link from "./Link";

export const COOKIE_LOCALES = {
  sv: 1351236282053,
  fi: 1351236282049,
};

const LanguageLink = ({ locale, currentLocale, translate, ...rest }) => {
  return (
    <Link
      variant="header"
      hreflang = {locale === "SV" ? "sv-FI" : "fi-FI"}
      children={
        locale === "SV"
          ? translate("language", null, "sv")
          : translate("language", null, "fi")
      } 
      sx={{
        display: "block",
        color: "text",
        fontSize: [2, null, 1],
      }}
      {...rest}
    />
  );
};

const LanguageSelector = ({ language, ...props }) => {
  const node = useRef();
  const [open, setOpen] = React.useState(false);
  const { setLanguage, translate } = useContext(LocalizationContext);
  const pages = useSitePages();
  const loc = (language || "fi").toUpperCase();

  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const handleChange = (lang, prevLang) => {
    setLocale(lang);
    setOpen(false);
    sendLanguageSwitch(lang, prevLang);
    navigateToLocalizedPage(pages, lang);
  };

  const navigateToLocalizedPage = (pages, language) => {
    const currentPage = window.location.pathname;
    const { slug: localizedPageSlug } = getLocalizedUrlAndTitleFromSlug(
      currentPage,
      language,
      pages
    );
    if (localizedPageSlug) window.location.href = localizedPageSlug;
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const setLocale = (locale) => {
    setLanguage(locale);
    document.cookie = `preferredLocale=${COOKIE_LOCALES[locale]}; domain=fennia.fi; path=/`;
    localStorage.setItem("userPreferredLanguage", locale);
  };

  return (
    <div ref={node} sx={{ position: "relative" }} {...props}>
      {loc == "SV" && (
        <LanguageLink
          locale="FI"
          currentLocale={loc}
          translate={translate}
          onClick={(e) => {
            e.preventDefault();
            handleChange("fi", pages, language);
          }}
        />
      )}
      {loc == "FI" && (
        <LanguageLink
          locale="SV"
          currentLocale={loc}
          translate={translate}
          onClick={(e) => {
            e.preventDefault();
            handleChange("sv", pages, language);
          }}
        />
      )}
    </div>
  );
};

export default LanguageSelector;
