/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { SvgIcon } from "@material-ui/core";
import React from "react";
import colors from "../gatsby-plugin-theme-ui/colors";

const Icon = ({ forwardRef, children, ...props }) => (
  <Box
    as="svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    sx={{
      display: "block",
      "> path": {
        fill: "currentColor",
      },
    }}
    ref={forwardRef}
  >
    {children}
  </Box>
);

export const ArrowRight = (props) => (
  <Icon width="19.503" height="12.234" viewBox="0 0 19.503 12.234" {...props}>
    <path
      d="m-1 14.333a.355.355 0 0 0 .356.356h16.431l-4.371 4.371a.356.356 0 1 0 .5.5l4.984-4.975a.352.352 0 0 0 .077-.116.357.357 0 0 0 -.077-.388l-4.982-4.981a.356.356 0 1 0 -.5.5l4.371 4.371h-16.433a.355.355 0 0 0 -.356.362z"
      fill="currentColor"
      stroke="#fff"
      strokeWidth="1.5"
      transform="translate(1.75 -8.183)"
    />
  </Icon>
);

export const ArrowRightGreen = (props) => (
  <Icon width="19.503" height="12.234" viewBox="0 0 19.503 12.234" {...props}>
    <path
      d="M-1,14.333a.355.355,0,0,0,.356.356H15.787L11.416,19.06a.356.356,0,1,0,.5.5L16.9,14.585a.352.352,0,0,0,.077-.116.357.357,0,0,0-.077-.388L11.918,9.1a.356.356,0,1,0-.5.5l4.371,4.371H-.644A.355.355,0,0,0-1,14.333Z"
      fill="currentColor"
      stroke={props.color}
      strokeWidth="1.5"
      transform="translate(1.75 -8.183)"
    />
  </Icon>
);

export const ArrowUp = (props) => (
  <Icon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <clipPath id="a">
      <path d="m0 0h24v24h-24z" />
    </clipPath>
    <g clipPath="url(#a)">
      <path d="m0 0h24v24h-24z" fill="#fff" />
      <path
        d="m8.54 4.469a1.635 1.635 0 0 0 -1.229.555l-6.976 7.929a1.313 1.313 0 0 0 1.972 1.734l6.1-6.934a.178.178 0 0 1 .267 0l6.1 6.934a1.313 1.313 0 1 0 1.972-1.734l-6.974-7.926a1.642 1.642 0 0 0 -1.232-.558z"
        fill="#484848"
        transform="translate(2.972 2.531)"
      />
    </g>
  </Icon>
);

export const ArrowDown = (props) => (
  <Icon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5.372 8l7.068 6.671 6.794-6.658 1.4 1.428-7.48 7.333a1 1 0 01-1.293.092l-.093-.079L4 9.454 5.372 8z"
    />
  </Icon>
);

export const Included = (props) => (
  <Icon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="m17.1688916 4 3.1649049 2.44609422-9.382 12.13899998c-.7183346.9294249-2.06812681 1.0353857-2.92247171.2618595l-.11332479-.1109375-4.916-5.2 2.90668809-2.7479382 3.30865595 3.5009691z"
      fill="#008a28"
    />
  </Icon>
);

export const SearchIcon = ({ forwardRef, ...props }) => (
  <Icon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    forwardRef={forwardRef}
    {...props}
  >
    <path
      fill="currentColor"
      d="M8 4a6 6 0 015.004 9.311l8.286 6.82-1.121 1.185-8.316-6.716A6 6 0 118 4zm0 1.6a4.4 4.4 0 100 8.8 4.4 4.4 0 000-8.8z"
    />
  </Icon>
);

export const CaretIcon = (props) => (
  <Icon width="10" height="6" viewBox="0 0 10 6" {...props}>
    <path d="M1.358.651a.5.5 0 1 0-.716.698l3.811 3.92a.762.762 0 0 0 1.094-.001l3.811-3.92a.5.5 0 0 0-.716-.697L5 4.396 1.358.651z" />
  </Icon>
);

export const CloseIcon = (props) => (
  <Icon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      d="M9.152 7.795a.16.16 0 010-.227L15.08 1.64A.96.96 0 1013.721.283L7.793 6.208a.16.16 0 01-.226 0L1.639.283A.96.96 0 00.281 1.64l5.927 5.928a.16.16 0 010 .227L.281 13.723a.96.96 0 001.358 1.357l5.928-5.928a.16.16 0 01.226 0l5.928 5.928a.96.96 0 001.358-1.357L9.152 7.795z"
      fill="currentColor"
    />
  </Icon>
);

export const InfoIcon = (props) => (
  <Icon width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="currentColor">
      <path d="M13.5 17.25A2.252 2.252 0 0111.25 15v-3.75h-.75a.75.75 0 010-1.5h.75c.827 0 1.5.673 1.5 1.5V15c0 .414.336.75.75.75h.75a.75.75 0 010 1.5h-.75z" />
      <circle cx="11.625" cy="7.125" r="1.125" />
      <path d="M12 24C5.383 24 0 18.617 0 12S5.383 0 12 0s12 5.383 12 12-5.383 12-12 12zm0-22.5C6.21 1.5 1.5 6.21 1.5 12S6.21 22.5 12 22.5 22.5 17.79 22.5 12 17.79 1.5 12 1.5z" />
    </g>
  </Icon>
);

export const NextIcon = (props) => (
  <Icon width="7" height="12" viewBox="0 0 7 12" {...props}>
    <path
      d="M1.029 0l5.765 5.617c.253.246.273.63.06.897l-.07.077L1.02 12l-1.01-.984L5.26 6.09 0 .965 1.029 0z"
      fill="currentColor"
    />
  </Icon>
);

export const PrevIcon = (props) => (
  <Icon width="7" height="12" viewBox="0 0 7 12" {...props}>
    <path
      d="M5.971 0L.206 5.617a.667.667 0 00-.06.897l.07.077L5.98 12l1.01-.984L1.74 6.09 7 .965 5.971 0z"
      fill="currentColor"
    />
  </Icon>
);

export const WarningIcon = (props) => (
  <Icon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g transform="translate(-2.991 -3.25)">
      <path
        d="M11.991,13.009a.716.716,0,0,1-.75-.676V6.926a.754.754,0,0,1,1.5,0v5.407A.716.716,0,0,1,11.991,13.009Z"
        transform="translate(-1 -0.571)"
      />
      <path
        d="M11.991,17.5a.8.8,0,0,1-.13-.011,1.126,1.126,0,0,1-.983-.982.717.717,0,0,1-.011-.131.817.817,0,0,1,.011-.131,1.127,1.127,0,0,1,.983-.983.774.774,0,0,1,.26,0,1.127,1.127,0,0,1,.983.983.7.7,0,0,1,.011.131.808.808,0,0,1-.011.131,1.126,1.126,0,0,1-.983.983A.913.913,0,0,1,11.991,17.5Z"
        transform="translate(-1 -1.524)"
      />
      <path d="M10.991,19.25a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10.991,19.25Zm0-14.667a6.667,6.667,0,1,0,6.667,6.667A6.675,6.675,0,0,0,10.991,4.583Z" />
    </g>
  </Icon>
);

export const LogoBG = () => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width="220"
    height="440"
    viewBox="0 0 261.344 523.493"
    sx={{ display: "block" }}
  >
    <path
      d="m261.344 0v61.918c-53.073 0-96.5 52.269-96.5 117.4h-68.344c0-98.904 73.977-179.318 164.844-179.318m0 172.085v61.915c-53.073 0-96.5 52.269-96.5 117.4v172.093h-68.344v-172.085c0-64.331-43.427-117.408-96.5-117.408v-61.915c53.073 0 100.517 27.341 131.074 69.96 29.753-42.619 77.2-69.96 130.27-69.96"
      fill="#00a830"
    />
  </Box>
);

export const LockIcon = (props) => (
  <Icon width="18" height="24" viewBox="0 0 18 24" {...props}>
    <path d="M9 0c3.24 0 5.88 2.675 5.996 6.02l.004.23V9h3v15H0V9h3V6.25C3 2.798 5.686 0 9 0zm7 11H2v11h14V11zm-7 3a1 1 0 01.993.883L10 15v3a1 1 0 01-1.993.117L8 18v-3a1 1 0 011-1zM9 2C6.813 2 5.028 3.793 4.925 6.044l-.005.206V9h8.16V6.25C13.08 3.903 11.253 2 9 2z" />
  </Icon>
);

export const ExternalLinkIcon = (props) => (
  <React.Fragment>
    {" "}
    <Icon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path d="M7.515 2.917a.686.686 0 01.093 1.365l-.093.007H1.661a.29.29 0 00-.282.222l-.007.066v9.76c0 .138.096.254.224.284l.067.008h9.757a.293.293 0 00.284-.225l.008-.066V8.482a.686.686 0 011.365-.093l.006.093v5.857a1.664 1.664 0 01-1.526 1.655l-.136.006H1.663a1.663 1.663 0 01-1.657-1.526L0 14.338V4.575a1.662 1.662 0 011.526-1.653l.137-.005h5.852zM15.8.201c.08.08.137.175.169.276l.028.135L16 .69v4.389a.686.686 0 01-1.365.093l-.007-.093V2.34l-8.59 8.59A.686.686 0 015 10.038l.066-.077 8.588-8.587h-2.731a.686.686 0 01-.68-.592L10.238.69c0-.347.258-.634.593-.68l.093-.006h4.33a.684.684 0 01.545.198z" />
    </Icon>
  </React.Fragment>
);

export const MoveUp = (props) => (
  <SvgIcon
    fontSize={"medium"}
    component={"svg"}
    viewBox={"0 20 20"}
    titleAccess={"Takaisin ylös"}
  >
    <svg>
      <path
        d="m8.54 4.469a1.635 1.635 0 0 0 -1.229.555l-6.976 7.929a1.313 1.313 0 0 0 1.972 1.734l6.1-6.934a.178.178 0 0 1 .267 0l6.1 6.934a1.313 1.313 0 1 0 1.972-1.734l-6.974-7.926a1.642 1.642 0 0 0 -1.232-.558z"
        fill="#484848"
        transform="translate(2.972 2.531)"
      />
    </svg>
  </SvgIcon>
);

export const InsuranceHome = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <rect width="48" height="48" fill="none" />
    <path
      d="M-483,2099v-2h12v-15.061h10V2097h10v-23.724l-15-12-15,12V2088h-2v-13.119l-2.375,1.9-1.249-1.562,20.624-16.5,20.625,16.5-1.25,1.562-2.375-1.9V2099Zm14-2h6v-13.061h-6Zm-2-24a5,5,0,0,1,5-5,5,5,0,0,1,5,5,5.005,5.005,0,0,1-5,5A5,5,0,0,1-471,2073Zm2,0a3,3,0,0,0,3,3,3,3,0,0,0,3-3,3,3,0,0,0-3-3A3,3,0,0,0-469,2073Z"
      transform="translate(490 -2054.857)"
      fill={props.fill ? props.fill : colors.primary}
    />
  </svg>
);

export const InsuranceLife = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <rect width="48" height="48" fill="none" />
    <path
      d="M44,25.084A19.883,19.883,0,0,0,25,7.042V4H23V7.043a19.953,19.953,0,0,0-5.224.974l.631,1.9A18.016,18.016,0,0,1,41.762,23.568,12.849,12.849,0,0,0,37.908,23a9.307,9.307,0,0,0-6.884,2.554A9.368,9.368,0,0,0,24.092,23a9.368,9.368,0,0,0-6.933,2.554A9.306,9.306,0,0,0,10.276,23a12.831,12.831,0,0,0-3.846.566,17.935,17.935,0,0,1,2.353-6.039l-1.7-1.055a20.015,20.015,0,0,0-2.891,8.608l-.181,1.9,1.665-.93A9.751,9.751,0,0,1,10.276,25c2.87,0,4.188.781,6.233,2.65l.707.646.676-.678A7.382,7.382,0,0,1,23,25.045V40a2,2,0,0,1-4,0V37H17v3a4,4,0,0,0,8,0V25.035a7.4,7.4,0,0,1,5.292,2.583l.676.678.707-.646C33.72,25.781,35.037,25,37.908,25a9.745,9.745,0,0,1,4.6,1.052l1.664.931Z"
      transform="translate(-0.094)"
      fill={props.fill ? props.fill : colors.primary}
    />
  </svg>
);

export const InsurancePet = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <rect width="48" height="48" fill="none" />
    <path
      d="M-209.574,2099c-4.488,0-8.426-3.685-8.426-7.886v-4.441a6.383,6.383,0,0,1-5.93-5.206A6.5,6.5,0,0,1-222.1,2076h-3.055c-2.648,0-6.848-1.629-6.848-6v-4h4.54c1.66,0,2.94-.787,3.217-1.548l.889-2.373A5.388,5.388,0,0,1-218.46,2059h4a13.583,13.583,0,0,1,7.029,2.177l-1.137,1.646A11.712,11.712,0,0,0-214.46,2061h-4a3.485,3.485,0,0,0-3.02,1.781l-.887,2.364c-.611,1.677-2.708,2.855-5.093,2.855H-230v2c0,3.265,3.59,4,4.848,4h5.5a15.358,15.358,0,0,1,2.4-1.217c3.02-1.205,4.251-3.171,4.251-6.783h2c0,4.395-1.751,7.14-5.509,8.641a12.785,12.785,0,0,0-2.491,1.33v.03h-.045c-1.824,1.272-3.228,3.062-2.909,5.167.372,2.433,2.845,3.527,4.483,3.527.193.007,3.2.153,4.272-2.284a4.4,4.4,0,0,1-.8-2.41v-.462l3.408-2.9.115-.346a6.157,6.157,0,0,1,5.477-4.213v-5.808l1.331.468c.473.161,11.669,4.085,11.669,14.146v10.232c0,4.2-3.937,7.886-8.426,7.886Zm1-22.081-.292.876-3.076,2.62a4.755,4.755,0,0,0,3.9,3.018c1.909,0,4.044,1.533,4.044,3.583V2097h3.575c3.423,0,6.425-2.75,6.425-5.886v-10.232c0-6.849-6.21-10.446-9-11.718v4.9h-1.627A4.16,4.16,0,0,0-208.578,2076.918ZM-216,2086.6v4.51c0,3.136,3,5.886,6.426,5.886H-206v-9.985c0-.77-1.05-1.583-2.044-1.583a6.287,6.287,0,0,1-3.709-1.471A6.1,6.1,0,0,1-216,2086.6Zm11-7.6a2,2,0,0,1,2-2,2,2,0,0,1,2,2Zm-15-12a2,2,0,0,1,2-2,2,2,0,0,1,2,2Z"
      transform="translate(236 -2055)"
      fill={props.fill ? props.fill : colors.primary}
    />
  </svg>
);

export const InsuranceTravel = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <rect width="48" height="48" fill="none" />
    <path
      d="M33,24V22a3,3,0,0,0-6,0v2H25V18a6.007,6.007,0,0,0-6-6V4H10v8a6.007,6.007,0,0,0-6,6v2H6V18a4,4,0,0,1,4-4h9a4,4,0,0,1,4,4v6H16V38H10a4,4,0,0,1-4-4V31H4v3a6,6,0,0,0,4,5.65V41a3,3,0,0,0,6,0V40h2v2h4v2h2V42H38v2h2V42h4V24ZM12,6h5v6H12Zm0,35a1,1,0,0,1-2,0V40h2Zm11-1H18V26h5Zm6-18a1,1,0,0,1,2,0v2H29Zm6,18H25V26H35Zm7,0H37V26h5Z"
      transform="translate(0)"
      fill={props.fill ? props.fill : colors.primary}
    />
  </svg>
);

export const InsurancePerson = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <rect width="48" height="48" fill="none" />
    <path
      d="M-2111,2098v-11a6,6,0,0,0-6-6,6,6,0,0,0-6,6l0,1.727a7.961,7.961,0,0,1,2,5.273v4h-2v-4a6,6,0,0,0-6-6,6,6,0,0,0-6,6v4h-2v-4a7.956,7.956,0,0,1,2-5.274V2087a6,6,0,0,0-6-6,6,6,0,0,0-6,6v11h-2v-11a8,8,0,0,1,8-8,8,8,0,0,1,8,8v.083a7.93,7.93,0,0,1,8,0V2087a8.01,8.01,0,0,1,8-8,8.01,8.01,0,0,1,8,8v11Zm-22-17.854a4,4,0,0,1,4-4,4,4,0,0,1,4,4,4,4,0,0,1-4,4A4,4,0,0,1-2133,2080.146Zm2,0a2,2,0,0,0,2,2,2,2,0,0,0,2-2,2,2,0,0,0-2-2A2,2,0,0,0-2131,2080.146Zm8.355-8.823a5.323,5.323,0,0,1,5.323-5.323,5.324,5.324,0,0,1,5.323,5.323,5.323,5.323,0,0,1-5.322,5.323A5.33,5.33,0,0,1-2122.646,2071.322Zm2,0a3.323,3.323,0,0,0,3.323,3.323,3.323,3.323,0,0,0,3.323-3.323,3.323,3.323,0,0,0-3.322-3.323A3.325,3.325,0,0,0-2120.646,2071.323Zm-26,0a5.324,5.324,0,0,1,5.323-5.323,5.324,5.324,0,0,1,5.323,5.323,5.324,5.324,0,0,1-5.323,5.323A5.329,5.329,0,0,1-2146.646,2071.322Zm2,0a3.322,3.322,0,0,0,3.323,3.323,3.323,3.323,0,0,0,3.323-3.323,3.323,3.323,0,0,0-3.323-3.323A3.325,3.325,0,0,0-2144.645,2071.323Zm10.567-6.987a3.007,3.007,0,0,1-.884-2.192,3.165,3.165,0,0,1,3.107-3.107h.046a3.012,3.012,0,0,1,2.146.885l.75.75.793-.793a3.06,3.06,0,0,1,4.328,0,3.059,3.059,0,0,1,0,4.328l-5.207,5.207Zm2.268-3.3h-.016a1.143,1.143,0,0,0-.794.341,1.14,1.14,0,0,0-.342.795,1.026,1.026,0,0,0,.3.748l3.665,3.665,3.792-3.793a1.063,1.063,0,0,0,.323-1.048,1.063,1.063,0,0,0-.776-.775,1.061,1.061,0,0,0-1.048.322l-2.207,2.208-2.165-2.165a1.027,1.027,0,0,0-.725-.3Z"
      transform="translate(2153 -2054.491)"
      fill={props.fill ? props.fill : colors.primary}
    />
  </svg>
);

export const InsuranceCar = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <rect width="48" height="48" fill="none" />
    <path
      d="M2321,1807v-2h-18v2a4,4,0,0,1-8,0v-4h32v-3h-6v-1a5.006,5.006,0,0,1,5-5h1v-3h-35v-6h4.816l3.061-7.462A6.03,6.03,0,0,1,2305.5,1774h12.969a6.016,6.016,0,0,1,5.639,3.579l2.973,7.421H2332v6h-3v16a4,4,0,0,1-8,0Zm2,0a2,2,0,0,0,4,0v-2h-4Zm-26,0a2,2,0,0,0,4,0v-2h-4Zm26.171-9H2327v-2h-1A3.005,3.005,0,0,0,2323.171,1798Zm6.829-9v-2h-2.118l.8,2Zm-3.472,0-4.275-10.678a4.01,4.01,0,0,0-3.784-2.323H2305.5a4.021,4.021,0,0,0-3.774,2.3l-4.389,10.7h13.516v-.332a5.573,5.573,0,0,1,11.146,0V1789h4.528Zm-6.528,0v-.332a3.573,3.573,0,0,0-7.146,0V1789Zm-26,0h1.175l.82-2h-2Zm1,11v-6h3a5.006,5.006,0,0,1,5,5v1Zm2-2h3.828a3,3,0,0,0-2.828-2h-1Zm11,0v-2h8v2Z"
      transform="translate(-2288 -1768.5)"
      fill={props.fill ? props.fill : colors.primary}
    />
  </svg>
);

export const InsuranceBoat = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <rect width="48" height="48" fill="none" />
    <path
      d="M22,3.746V28H8.767l6.891-11.485-1.715-1.029L5.233,30H22v4H6v1a9.01,9.01,0,0,0,9,9H33.38l9.94-8.836Zm2,6.509L40.113,34H24ZM32.62,42H15a7.011,7.011,0,0,1-6.929-6h31.3Z"
      transform="translate(-0.277 0.127)"
      fill={props.fill ? props.fill : colors.primary}
    />
  </svg>
);

export const InsuranceAcquisition = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <path
      fill={props.fill ? props.fill : colors.primary}
      d="M40,36H38V22H34V9H18V22H14v7h2V24h7v5h6V24h7V36H12V11A6.00657,6.00657,0,0,0,6,5H4V7H6a4.004,4.004,0,0,1,4,4V36.142A3.99348,3.99348,0,1,0,15,40a3.95435,3.95435,0,0,0-.55621-2H36.55621A3.95435,3.95435,0,0,0,36,40a4,4,0,1,0,4-4ZM25,11h2v3H25Zm2,16H25V24h2Zm-7-5V11h3v5h6V11h3V22ZM11,42a2,2,0,1,1,2-2A2.0026,2.0026,0,0,1,11,42Zm29,0a2,2,0,1,1,2-2A2.0026,2.0026,0,0,1,40,42Z"
    />
  </svg>
);

export const InsuranceCompanies = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <path
      fill={props.fill ? props.fill : colors.primary}
      d="M23.99609,12a4.00057,4.00057,0,1,0-4.06787-4A4.039,4.039,0,0,0,23.99609,12Zm0-6a2.00111,2.00111,0,1,1-2.06787,2A2.03743,2.03743,0,0,1,23.99609,6Z"
    />
    <polygon
      fill={props.fill ? props.fill : colors.primary}
      points="38 42 35.996 42 35.996 30 33.996 30 33.996 42 29 42 29 19 27 19 27 42 25 42 25 29 23 29 23 42 21 42 21 19 19 19 19 42 15.208 42 14.004 42 14.004 30 12.004 30 12.004 42 10 42 10 19 8 19 8 44 12.004 44 13 44 13.004 44 15.208 44 19 44 29 44 40 44 40 19 38 19 38 42"
    />
    <path
      fill={props.fill ? props.fill : colors.primary}
      d="M38,13H10a6.00657,6.00657,0,0,0-6,6V29H6V19a4.00427,4.00427,0,0,1,4-4h6.54047A5.96852,5.96852,0,0,0,15,19V29h2V19a4.00427,4.00427,0,0,1,4-4h6a4.004,4.004,0,0,1,4,4V29h2V19a5.96852,5.96852,0,0,0-1.54047-4H38a4.004,4.004,0,0,1,4,4V29h2V19A6.00657,6.00657,0,0,0,38,13Z"
    />
    <path
      fill={props.fill ? props.fill : colors.primary}
      d="M34.99609,12a4.00057,4.00057,0,1,0-4.06836-4A4.039,4.039,0,0,0,34.99609,12Zm0-6a2.00113,2.00113,0,1,1-2.06836,2A2.03743,2.03743,0,0,1,34.99609,6Z"
    />
    <path
      fill={props.fill ? props.fill : colors.primary}
      d="M13.00391,12A4.00057,4.00057,0,1,0,8.93555,8,4.03857,4.03857,0,0,0,13.00391,12Zm0-6a2.00113,2.00113,0,1,1-2.06836,2A2.03732,2.03732,0,0,1,13.00391,6Z"
    />
  </svg>
);

export const InsuranceClipboard = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <g id="ikons">
      <rect
        fill={props.fill ? props.fill : colors.primary}
        x="18"
        y="26"
        width="12"
        height="2"
      />
      <rect
        fill={props.fill ? props.fill : colors.primary}
        x="18"
        y="20"
        width="12"
        height="2"
      />
      <rect
        fill={props.fill ? props.fill : colors.primary}
        x="18"
        y="32"
        width="12"
        height="2"
      />
      <path
        fill={props.fill ? props.fill : colors.primary}
        d="M28,6v.03094a4.95453,4.95453,0,0,0-8,0V6H8V31h2V8h9.10107A5.00068,5.00068,0,0,0,19,9H17v2H13V40H35V11H31V9H29a5.00068,5.00068,0,0,0-.10107-1H38V43H8v2H40V6Zm3,9V13h2V38H15V13h2v2Zm-2-2H19V11h2V9a3,3,0,0,1,6,0v2h2Z"
      />
      <rect class="cls-1" x="23" y="8" width="2" height="2" fill={props.fill ? props.fill : colors.primary} />
    </g>
  </svg>
);

export const InsuranceCheckAll = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <rect width="48" height="48" fill="none" />
    <path
      d="M491,2079h2a18,18,0,1,0,9.47-15.86l-.94-1.76A20,20,0,1,1,491,2079Zm9.18-4.1,2.309-1.91,8.131,9.861,8.11-9.87,2.319,1.9-10.419,12.7Z"
      transform="translate(-487 -2055)"
      fill={props.fill ? props.fill : colors.primary}
    />
  </svg>
);

export const StarIcon = (props) => (
  <svg width="48px" height="48px" viewBox="0 0 48 48">
    <path
      d="m11.5,39.73c-.36,0-.71-.11-1.01-.33-.55-.39-.82-1.04-.71-1.71l1.74-10.79-7.91-7.55c-.49-.46-.66-1.15-.46-1.79.2-.64.74-1.1,1.4-1.21l10.8-1.68,4.74-9.85c.29-.6.89-.99,1.56-.99h.02c.66,0,1.26.37,1.56.96l4.94,9.75,10.83,1.46c.67.09,1.21.54,1.43,1.18.21.64.05,1.33-.42,1.8l-7.75,7.71,1.96,10.75c.12.66-.14,1.32-.68,1.72-.54.4-1.25.46-1.84.15l-9.73-4.99-9.62,5.19c-.26.14-.55.21-.83.21Zm-6.15-21.47l7.64,7.29c.42.4.61.97.52,1.54l-1.68,10.43,9.29-5.01c.51-.27,1.12-.28,1.63-.02l9.4,4.82-1.89-10.39c-.1-.57.08-1.15.49-1.55l7.48-7.45-10.46-1.41c-.57-.08-1.07-.43-1.33-.94l-4.77-9.42-4.57,9.52c-.25.52-.74.88-1.31.97l-10.43,1.63ZM21.44,5.7s0,0,0,0h0Z"
      fill={props.fill ? props.fill : colors.primary}
    />
  </svg>
);

export const CarouselPrevIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <rect width="24" height="24" fill="none" />
    <path
      d="M391.293,86.707,380.586,76l10.707-10.707,1.414,1.414L383.414,76l9.293,9.293Z"
      transform="translate(-375.5 -64)"
      fill={props.fill ? props.fill : colors.primary}
    />
  </svg>
);

export const CarouselNextIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <rect width="24" height="24" fill="none" />
    <path
      d="M382,86.707,392.707,76,382,65.293l-1.414,1.414L389.879,76l-9.293,9.293Z"
      transform="translate(-373.793 -64)"
      fill={props.fill ? props.fill : colors.primary}
    />
  </svg>
);

export const UmbrellaIcon = (props) => (
  <Icon width="26" height="26" viewBox="0 0 26 26" {...props}>
    <path
      d="M 23.78125 13.585938 C 23.285156 8.210938 18.886719 4.03125 13.492188 3.8125 L 13.492188 2.167969 L 12.40625 2.167969 L 12.40625 3.816406 C 11.445312 3.863281 10.492188 4.042969 9.578125 4.34375 L 9.917969 5.371094 C 12.582031 4.488281 15.496094 4.792969 17.917969 6.210938 C 20.335938 7.625 22.035156 10.011719 22.570312 12.765625 C 21.894531 12.558594 21.191406 12.453125 20.484375 12.457031 C 19.101562 12.390625 17.753906 12.890625 16.753906 13.839844 C 15.746094 12.886719 14.386719 12.386719 13 12.457031 C 11.609375 12.386719 10.253906 12.886719 9.242188 13.839844 C 8.242188 12.890625 6.894531 12.390625 5.515625 12.457031 C 4.808594 12.453125 4.105469 12.558594 3.433594 12.765625 C 3.65625 11.605469 4.085938 10.496094 4.707031 9.492188 L 3.785156 8.921875 C 2.914062 10.335938 2.378906 11.929688 2.21875 13.585938 L 2.121094 14.613281 L 3.023438 14.109375 C 3.796875 13.71875 4.652344 13.523438 5.515625 13.542969 C 7.070312 13.542969 7.785156 13.964844 8.890625 14.976562 L 9.273438 15.328125 L 9.640625 14.960938 C 10.339844 14.140625 11.335938 13.640625 12.40625 13.566406 L 12.40625 21.667969 C 12.40625 22.265625 11.921875 22.75 11.324219 22.75 C 10.726562 22.75 10.242188 22.265625 10.242188 21.667969 L 10.242188 20.042969 L 9.15625 20.042969 L 9.15625 21.667969 C 9.15625 22.863281 10.128906 23.832031 11.324219 23.832031 C 12.519531 23.832031 13.492188 22.863281 13.492188 21.667969 L 13.492188 13.5625 C 14.597656 13.609375 15.636719 14.117188 16.355469 14.960938 L 16.722656 15.328125 L 17.105469 14.976562 C 18.214844 13.964844 18.925781 13.542969 20.484375 13.542969 C 21.347656 13.523438 22.203125 13.71875 22.972656 14.113281 L 23.875 14.617188 Z M 23.78125 13.585938 "
      fill={props.fill ? props.fill : colors.primary}
    />
  </Icon>
);

export const ClipboardIcon = (props) => (
  <Icon width="26" height="26" viewBox="0 0 26 26" {...props}>
    <path
      d="M 4.332031 24.105469 L 4.332031 23.019531 L 20.582031 23.019531 L 20.582031 4.0625 L 15.652344 4.0625 C 15.691406 4.242188 15.707031 4.421875 15.707031 4.605469 L 16.792969 4.605469 L 16.792969 5.6875 L 18.957031 5.6875 L 18.957031 21.394531 L 7.042969 21.394531 L 7.042969 5.6875 L 9.207031 5.6875 L 9.207031 4.605469 L 10.292969 4.605469 C 10.292969 4.421875 10.308594 4.242188 10.347656 4.0625 L 5.417969 4.0625 L 5.417969 16.519531 L 4.332031 16.519531 L 4.332031 2.980469 L 10.832031 2.980469 L 10.832031 2.996094 C 11.339844 2.304688 12.144531 1.894531 13 1.894531 C 13.855469 1.894531 14.660156 2.304688 15.167969 2.996094 L 15.167969 2.980469 L 21.667969 2.980469 L 21.667969 24.105469 Z M 8.125 20.3125 L 17.875 20.3125 L 17.875 6.769531 L 16.792969 6.769531 L 16.792969 7.855469 L 9.207031 7.855469 L 9.207031 6.769531 L 8.125 6.769531 Z M 10.292969 6.769531 L 15.707031 6.769531 L 15.707031 5.6875 L 14.625 5.6875 L 14.625 4.605469 C 14.625 3.707031 13.898438 2.980469 13 2.980469 C 12.101562 2.980469 11.375 3.707031 11.375 4.605469 L 11.375 5.6875 L 10.292969 5.6875 Z M 9.75 18.144531 L 9.75 17.0625 L 16.25 17.0625 L 16.25 18.144531 Z M 9.75 14.894531 L 9.75 13.8125 L 16.25 13.8125 L 16.25 14.894531 Z M 9.75 11.644531 L 9.75 10.5625 L 16.25 10.5625 L 16.25 11.644531 Z M 12.457031 5.144531 L 12.457031 4.0625 L 13.542969 4.0625 L 13.542969 5.144531 Z M 12.457031 5.144531 "
      fill={props.fill ? props.fill : colors.primary}
    />
  </Icon>
);
