import { useStaticQuery, graphql } from "gatsby";

export const useContentfulMenu = () => {
  const { allContentfulComponent } = useStaticQuery(
    graphql`
      query ContentfulHeaderQuery {
        allContentfulComponent(filter: { template: { eq: "Menu" } }) {
          edges {
            node {
              id
              contentful_id
              __typename
              title
              node_locale
              childContentfulComponentDataJsonNode {
                pageType
                menu {
                  items {
                    slug
                    title
                  }
                  slug
                  title
                }
              }
            }
          }
        }
      }
    `
  );
  const menus = Array.isArray(allContentfulComponent.edges)
    ? allContentfulComponent.edges.map((item) => {
        return {
          ...item.node.childContentfulComponentDataJsonNode,
          locale: item.node.node_locale,
        };
      })
    : [];
  return menus;
};
