import axios from "axios";
import dayjs from "dayjs";

const MAX_EMAIL_FILE_SIZE = 5.9 * 1024 * 1024;

const apiServer = axios.create({
  baseURL: process.env.GATSBY_API_URL,
  timeout: 10000,
});

const pdfEndpoint = process.env.PDF_GENERATOR_API_URL;

export class FileSizeError extends Error {
  constructor(message) {
    super(message);
    this.name = "FileSizeError";
  }
}

const formApis = {
  changeAddress: "change-address",
  offerRequest: "offer-request",
  invoiceTime: "invoice-time",
  corporateContact: "corporate-contact",
  customerContact: "customer-contact",
  feedback: "feedback",
  callback: "callback",
  secureMove: "secure-move",
  accidentInsurance: "accident-insurance",
  danskeContact: "danske-contact",
  startupPackageContact: "startup-package-contact",
  customerFinancing: "customer-financing",
  danskeTipCorporate: "danske-tip-corporate",
  danskeCustomerContact: "danske-customer-contact",
  marketingPermission: "marketing-permission",
  greencard: "greencard",
  accessibilityFeedback: "accessibility-feedback",
  accessibleMaterialOrder: "accessible-material-order",
  customerContactRequest: "customer-contact-request",
  accidentInsuranceCampaign: "accident-insurance-campaign",
};

const convertToFormData = (vo) => {
  // split by files and other
  const formData = new FormData();
  let attachmentSize = 0;
  const [attachments, other] = Object.entries(vo).reduce(
    ([attachments, other], [key, value]) => {
      if (value instanceof File) {
        return [[...attachments, [key, value]], other];
      }
      if (
        Array.isArray(value) &&
        value.length > 0 &&
        value[0] instanceof File
      ) {
        return [[...attachments, ...value.map((v) => [key, v])], other];
      }
      return [attachments, [...other, [key, value]]];
    },
    [[], []]
  );
  other.map((entry) => formData.append(entry[0], entry[1]));
  for (const [i, [key, value]] of attachments.entries()) {
    attachmentSize = attachmentSize + value.size;
    // should be same as in the backend
    formData.append("attachment", value);
  }
  if (attachmentSize >= MAX_EMAIL_FILE_SIZE) {
    throw new FileSizeError("file size exceeded");
  }
  return formData;
};

export const sendForm = async ({ type, xAuthHeader, ...vo}) => {
  const formPath = formApis[type] || type;
  const customerClass = sessionStorage.getItem("preferredPageType");
  if (type === "feedback") {
    vo["customerClass"] = customerClass === "Company Customer" ? "yr" : "ha";
  }
  if (!formPath) throw new Error(`API not defined for form type: ${type}`);
  const headers = { "Content-Type": "multipart/form-data" };
  if (xAuthHeader !== false)  headers["x-auth-token"] = xAuthHeader;
  await apiServer.post(`/forms/${formPath}`, convertToFormData(vo), {
     headers,
  });
};

export const sendLoginSubmit = async ({ type, ...vo }) => {
  const response = await apiServer.post(`/forms/login/${type}`, convertToFormData(vo), {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const getFaultNotifications = async () => {
  const response = await apiServer.get(`/notification`);
  return response.data;
};

export const sendDanskeAdminLogin = async ({ type, ...vo }) => {
  const response = await apiServer.post("/danskebank/login", vo);
  return response;
};

export const getDanskeAdminLeadData = async (authToken, formType, params) => {
  if (params.format == "csv") {
    const response = await apiServer.get(
      `/danskebank/leads-list/${formType}?months=${params.months}&format=csv`,
      {
        headers: { "x-auth-token": authToken },
      }
    );
    const link = document.createElement("a");
    link.href = "data:text/csv," + encodeURIComponent(response.data);
    link.download = "danskebank-leads-" + dayjs().format("DDMMYYYY") + ".csv";
    link.click();
  } else {
    const response = await apiServer.get(
      `/danskebank/leads-list/${formType}?months=${params.months}`,
      {
        headers: { "x-auth-token": authToken },
      }
    );
    return response;
  }
};

export const getLifePrice = async (
  lifeProtectionCompensationAmount,
  disabilityProtectionCompensationAmount,
  seriousIllnessCompensationAmount,
  isSmoker,
  birthYear
) => {
  try {
    let disabilitySum;
    if (typeof disabilityProtectionCompensationAmount === "number") {
      disabilitySum = disabilityProtectionCompensationAmount;
    } else {
      disabilitySum = 20000;
    }
    let seriousIllnessSum;
    if (typeof seriousIllnessCompensationAmount === "number") {
      seriousIllnessSum = seriousIllnessCompensationAmount;
    } else {
      seriousIllnessSum = 20000;
    }
    const response = await apiServer.get(
      `/prices/life/?lifeProtectionCompensationAmount=${lifeProtectionCompensationAmount}&disabilityProtectionCompensationAmount=${disabilitySum}&seriousIllnessCompensationAmount=${seriousIllnessSum}&isSmoker=${isSmoker}&birthYear=${birthYear}`
    );
    if (typeof disabilityProtectionCompensationAmount !== "number") {
      response.data.disabilityProtection.yearlyPrice = 0;
      response.data.disabilityProtection.discountedMonthlyPrice = 0;
      response.data.disabilityProtection.discountedYearlyPrice = 0;
      response.data.disabilityProtection.monthlyPrice = 0;
    }
    if (typeof seriousIllnessCompensationAmount !== "number") {
      response.data.criticalIllnessProtection.yearlyPrice = 0;
      response.data.criticalIllnessProtection.discountedMonthlyPrice = 0;
      response.data.criticalIllnessProtection.discountedYearlyPrice = 0;
      response.data.criticalIllnessProtection.monthlyPrice = 0;
    }
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getPairLifePrice = async (
  compensationAmount,
  birthYear,
  secondPersonBirthYear
) => {
  try {
    const response = await apiServer.get(
      `/prices/life/pair?compensationAmount=${compensationAmount}&birthYear=${birthYear}&secondPersonBirthYear=${secondPersonBirthYear}`
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const generatePDF = async (slug, title) => {
  try {
    const response = await axios({
      method: "post",
      url: pdfEndpoint,
      data: {
        url: process.env.PDF_GENERATOR_BASE_URL + `/${slug}?print`,
        creator: "Fennia",
        producer: "Fennia",
        keywords: ["Fennia", "Vakuutus"],
        subject: title,
        title: title,
        author: "Fennia",
      },
    });
    return response.data;
  } catch (e) {
    console.error(e);
  }
};
