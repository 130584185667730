/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import { useState } from "react";
import Link from "../Link";
import { isMobile } from "react-device-detect";
import Dropdown from "./Dropdown";
import DropdownMenu from "./DropdownMenu";
import DropdownLink from "./DropdownLink";

const navLinkBase = {
  width: ["100%", null, "auto"],
  py: [2, null, 0],
  color: "text",
  fontFamily: ["heading", null, "body"],
  borderBottomWidth: ["1px", null, 0],
  borderBottomStyle: ["solid", null, "none"],
  borderBottomColor: "lightGrey",
};

const NavLink = ({ forwardRef, ...props }) => (
  <Link
    {...props}
    variant="header"
    sx={{
      ...navLinkBase,
      fontSize: [4, null, 1],
      ml: [0, null, 2],
    }}
    forwardRef={forwardRef}
  >
    {props.children}
  </Link>
);

const Menu = ({
  headerMenu,
  menuIsVisible,
  showMenu,
  toggleSelectedMenu,
  onKeyDownHandler,
  forwardRef,
  ...rest
}) => {
  return (
    <Flex
      as="nav"
      sx={{
        position: "relative",
        zIndex: 99,
        flexDirection: ["column", null, "row"],
        width: ["100%", null, "auto"],
        alignItems: "flex-start",
      }}
      {...rest}
    >
      {headerMenu &&
        headerMenu.map(({ title, slug, items }, index) => {
          if (items) {
            return (
              <DropDownMenuWrapper
                title={title}
                items={items}
                key={title}
                isOpen={showMenu[index] && menuIsVisible}
                index={index}
                onKeyDown={(event) => onKeyDownHandler(event, index)}
                toggleSelectedMenu={() => toggleSelectedMenu(index)}
                forwardRef={(element) => (forwardRef.current[index] = element)}
                tabIndex={-1}
              />
            );
          }

          return (
            <NavLink
              target={slug}
              activeStyle="active"
              key={title}
              onKeyDown={(event) => onKeyDownHandler(event, index)}
              forwardRef={(element) => (forwardRef.current[index] = element)}
            >
              {title}
            </NavLink>
          );
        })}
    </Flex>
  );
};

const DropDownMenuWrapper = ({
  title,
  items,
  isOpen,
  index,
  toggleSelectedMenu,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const onClickHandler = (e) => {
    if (!isOpen) {
      setIsHovered(false);
    }
    toggleSelectedMenu(e.target);
  };

  const onMouseEnterHandler = () => {
    if (isMobile) return;
    if (!isOpen) {
      setIsHovered(true);
    }
  };

  const onMouseLeaveHandler = () => {
    if (isMobile) return;
    setIsHovered(false);
  };

  return (
    <Dropdown
      title={title}
      key={title}
      showAccordion
      onClick={onClickHandler}
      buttonStyle={{ color: "text", fontWeight: "body" }}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      isHovered={isHovered}
      isOpen={isOpen}
      {...rest}
    >
      <DropdownMenu showMenu={isOpen || isHovered}>
        {items.map(({ title, slug }) => {
          return (
            <DropdownLink target={slug} key={title}>
              {title}
            </DropdownLink>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default Menu;
