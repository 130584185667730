/** @jsx jsx  */
import { jsx, Box } from "theme-ui";

const VisuallyHidden = ({ children }) => (
  <Box
    sx={{
      position: "absolute !important",
      height: 1,
      width: 1,
      overflow: "hidden",
      clip: "rect(1px 1px 1px 1px)",
      clip: "rect(1px, 1px, 1px, 1px)",
      whiteSpace: "nowrap",
    }}
  >
    {children}
  </Box>
);

export default VisuallyHidden;
