import { CONTENTFUL_CONSTANTS } from "./constants";
import { getSrc } from "gatsby-plugin-image";

export function getUrlFromSlug(slug, nodes) {
  if (!slug) return slug;
  //if (!slug.startsWith('/')) slug = '/'+slug;
  if (nodes) {
    let node = nodes.find((edge) => edge.context && edge.context.slug === slug);
    let language = (node && node.context && node.context.language) || "fi";
    if (node && node.context.paths && node.context.paths[language]) {
      return node.context.paths[language];
    }
  }
  return slug;
}

export function isValidEmail(email) {
  const pattern = /\S+@\S+\.\S+/;
  return pattern.test(email);
}

export function isValidPhoneNumber(phoneNumber) {
  const pattern = /^(tel:)?[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
  return pattern.test(phoneNumber);
}

export function isValidUrl(url) {
  const pattern = /(https?):\/\/[^\s$.?#].[^\s]*/;
  return pattern.test(url);
}

export const isBrowser = typeof window !== "undefined";

export const getPageHash = () => {
  let hash = isBrowser && window && window.location && window.location.hash;
  hash = hash && hash.startsWith("#") ? hash.substring(1, hash.length) : null;
  return hash;
};

export const generateAncorHash = (title) => {
  if (!title) return null;
  title = title.replace(/[äÄåÅ]/g, "a");
  title = title.replace(/[öÖ]/g, "o");
  title = title.replace(/[^a-zA-Z0-9/-]/g, "");
  if (title.length < 1) return null;
  return title.toLowerCase();
};

export const handleFormValidationError = (
  error,
  showNotification,
  severity
) => {
  if (error.response && error.response.status === 400) {
    const errRegexp = /^\[\d+\] (.*$)/; // "[1] Email is not valid"
    const messages = error.response.data
      .split("\n")
      .map((s) => (errRegexp.exec(s) || [])[1])
      .filter((s) => !!s);
    if (messages.length > 0) {
      showNotification(messages.join(" "), severity);
      return true;
    }
  }
  return false;
};

export const getLocalizedUrlAndTitleFromSlug = (slug, language, pages) => {
  return pages.reduce((pageObj, page) => {
    if (
      page.path === slug ||
      page.path === `${slug}/` ||
      `${page.path}/` === slug
    ) {
      if (page.context && page.context.titles && page.context.paths) {
        pageObj.title = page.context.titles[language];
        pageObj.slug = page.context.paths[language];
      }
    }
    return pageObj;
  }, {});
};

export const parseJsonValues = (stringArray) => {
  return stringArray.reduce((res, c) => {
    const [key, val] = c.split("=").map(decodeURIComponent);
    try {
      return Object.assign(res, { [key]: JSON.parse(val) });
    } catch (e) {
      return Object.assign(res, { [key]: true });
    }
  }, {});
};

export const getCookies = () => {
  return parseJsonValues(document.cookie.split(";"));
};

export const removeHtmlTags = (htmlString) => {
  return htmlString.replace(/<[^>]+>/g, "");
};

export const matchAndReplace = (entity, contentfulVariables) => {
  if (entity && (typeof entity === "string" || entity instanceof String)) {
    const VARIABLES = { ...CONTENTFUL_CONSTANTS, ...contentfulVariables };
    const regexes = new RegExp(
      Object.keys(VARIABLES)
        .map((item) => `\\$${item}`)
        .join("|"),
      "gi"
    );

    // Replace non-breaking spaces with regular spaces
    entity = entity.replace(
      new RegExp(String.fromCharCode(160), "g"),
      String.fromCharCode(32)
    );

    return entity.replace(regexes, (matched) => {
      return VARIABLES[matched.replace("$", "")];
    });
  }

  return entity;
};

export const createImageUrl = (imageUrl) => {
  const src = getSrc(imageUrl);
  let metaImage =
    src ||
    (imageUrl &&
      imageUrl.images &&
      imageUrl.images.fallback &&
      imageUrl.images.fallback.src) ||
    (imageUrl &&
      imageUrl.images &&
      imageUrl.images.sources &&
      imageUrl.images.sources[0].srcSet) ||
    "";

  if (metaImage.startsWith("//")) {
    metaImage = "https:" + metaImage;
    // Url parameters break the links beginning with https://images.ctfassets.net/
    if (metaImage.includes("?")) {
      metaImage = metaImage.split("?")[0];
    }
    return metaImage;
  }
  return metaImage;
};

export const saveFaultNotificationsToSessionStorage = (
  faultNotifications,
  fetchDateTime
) => {
  sessionStorage.setItem(
    "faultNotifications",
    JSON.stringify({
      timestamp: fetchDateTime.valueOf(),
      faultNotifications: faultNotifications,
    })
  );
};

export const getFaultNotificationsFromSessionStorage = () => {
  return JSON.parse(sessionStorage.getItem("faultNotifications"));
};

export const saveClosedNotificationsToSessionStorage = (
  removedNotifications
) => {
  sessionStorage.setItem(
    "removedFaultsNotifications",
    JSON.stringify({
      faultNotifications: removedNotifications,
    })
  );
};

export const getClosedNotificationsToSessionStorage = () => {
  const item = JSON.parse(sessionStorage.getItem("removedFaultsNotifications"));
  return item && item.faultNotifications;
};

// export const createImageUrl = (imageUrl) => {
//   let metaImage = imageUrl;
//   if (imageUrl.startsWith("//")) {
//     metaImage = "https:" + imageUrl;
//     // Url parameters break the links beginning with https://images.ctfassets.net/
//     if (metaImage.includes("?")) {
//       metaImage = metaImage.split("?")[0];
//     }
//     return metaImage;
//   }
// };
