/** @jsx jsx */
import { jsx, Input } from "theme-ui";
import React, { useEffect, useContext } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
//import { Input } from "./styles"
import { SearchContext } from "../../context/SearchContext";

const AlgoliaSearchBox = connectSearchBox(
  ({ currentRefinement, refine, value, ...rest }) => {
    const { setSearchTerm } = useContext(SearchContext);
    useEffect(() => {
      if (value.length >= 3 && value !== currentRefinement) {
        setSearchTerm(value);
        refine(value);
      }
    }, [refine, value, currentRefinement, setSearchTerm]);
    return <Input type="hidden" aria-label="Search" />;
  }
);

export default AlgoliaSearchBox;
