import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query siteMetaData {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl: url
            titleTemplate
            defaultImage: image
            social { 
              facebook,
              twitter,
              youtube,
              linkedIn,
              instagram
            }
            author
            organization {
              address,
              postalCode,
              addressLocality,
            }
            postsPerPage
          }
        }
      }
    `
  );
  return site.siteMetadata;
};
