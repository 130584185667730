/** @jsx jsx */
import { jsx, Container, Button } from "theme-ui";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { CloseIcon } from "./Icon";
import { motion, AnimatePresence } from "framer-motion";
import { NotificationContext, SEVERITY } from "../context/NotificationContext";

const Notification = ({ notification: { id, severity, msg } }) => {
  const { hideNotification } = useContext(NotificationContext);
  const [isVisible, setVisible] = useState(false);

  const hide = useCallback(e => {
    setVisible(false);
    hideNotification(id);
  });

  useEffect(() => {
    const timer = setTimeout(() => hide(), 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <AnimatePresence>
      <motion.div
        key={id}
        initial={{ translateY: -100, translateX: "-50%" }}
        animate={{ translateY: 0 }}
        exit={{ translateY: -100 }}
        sx={{
          position: "fixed",
          top: 0,
          zIndex: 999,
          left: "50%",
          transform: "translateX(-50%)",
          maxWidth: 600,
          width: "100%",
          bg: severity === SEVERITY.INFO ? "success" : "error",
        }}
        role="alertdialog"
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 2,
            color: "white",
          }}
          tabIndex={0}
          role="alert"
          aria-live="assertive"
        >
          {msg}
          <Button
            variant="plain"
            onClick={hide}
            sx={{
              flex: "none",
              color: "white",
              ":hover": { color: "white", opacity: 0.8 },
            }}
          >
            <CloseIcon />
          </Button>
        </Container>
      </motion.div>
    </AnimatePresence>
  );
};

export default Notification;
