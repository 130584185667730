import React from "react"
import styled from "@emotion/styled"
import { css }  from "@emotion/react";

export const Root = styled.div`
  display: grid;
  grid-gap: 1em;
`

const collapse = props => css`
  cursor: pointer;
  ::placeholder {
    color: ${props.theme.gray};
  }
`

const expand = props => css`
  background: ${props.theme.veryLightGray};
  width: 4em;
`

export const Input = styled.input`
  outline: none;
  border: none;
  font-size: 1em;
  background: transparent;
  transition: ${props => props.theme.shortTrans};
  {hightlight-next-line}
  ${props => (props.collapse ? collapse : expand)};
`
